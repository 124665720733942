import React from "react";

import {
  Button,
  CollapsibleContainer,
  CollapsibleRow,
  Column,
  ContentArea,
  Divider,
  HighlightedReminder,
  List,
  Row,
  Stack,
  Text,
  Well
} from "@gigsmart/atorasu";
import { DirectPaymentsOnly } from "@gigsmart/feature-flags";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import { createRelayFragmentContainer, graphql } from "@gigsmart/relay";
import useLinkToDirectHirePolicy from "../useLinkToDirectHirePolicy";
import type { DirectHirePaymentCollapsibleCard_Worker$key } from "./__generated__/DirectHirePaymentCollapsibleCard_Worker.graphql";

interface Props {
  directHireBonus: string;
}
const previousYear = new Date().getFullYear() - 1;

export const DirectPaymentCollapsibleCard = ({ directHireBonus }: Props) => {
  const history = useHistory();
  const handleLinkPress = useLinkToDirectHirePolicy("worker");
  const humanizedBonus = currency.humanize(directHireBonus ?? 0, {
    integer: true
  });

  return (
    <CollapsibleRow
      testID="direct-hire-payments-collapsible"
      title={DirectPaymentsOnly.select(
        "Direct Payment Bonus",
        "Direct Payment & Hire Bonus"
      )}
      icon="money-bill-wave"
      iconColor="primary"
    >
      <ContentArea>
        <Stack>
          <Text>
            Were you
            <Text weight="bold">
              {DirectPaymentsOnly.select(
                " paid outside the app or hired as a non-W2 employee for an Organization ",
                " paid outside of the app or hired directly by an Organization "
              )}
            </Text>
            you met through GigSmart?
          </Text>
          <Well color="success" size="compact">
            <Column alignItems="center">
              <Text variant="subheader" color="black" weight="semibold">
                You may be eligible to receive a
              </Text>
              <Text color="success" weight="bold" italic>
                {humanizedBonus}{" "}
                {DirectPaymentsOnly.select(
                  "Direct Payment Bonus!",
                  "Direct Payment & Hire Bonus!"
                )}
              </Text>
            </Column>
          </Well>
          <Row justifyContent="center">
            <Button
              testID="submit-direct-hire-request-btn"
              size="small"
              label={DirectPaymentsOnly.select(
                "Submit a Direct Payment Request",
                "Submit a Direct Payment & Hire Request"
              )}
              onPress={() => history.push("direct-hire/add")}
            />
          </Row>
        </Stack>
      </ContentArea>
      <Divider />
      <CollapsibleContainer
        startCollapsed
        header={null}
        testID="bonus-requirements"
      >
        <ContentArea>
          <Stack>
            <Text>
              In order to be eligible for the bonus, you must meet the following
              requirements:
            </Text>
            <ContentArea size="none" variant="compact">
              <List variant="bullet">
                <Text>Must have an active GigSmart Get Gigs account</Text>
                <Text>Have applied to at least one Shift Gig</Text>
                {DirectPaymentsOnly.select(
                  <Text>
                    Have support that clearly shows Organization information and
                    evidence of payment outside the app or evidence you were
                    brought on as a non-W2 employee for the Organization within
                    90 days of the last engagement with the Organization through
                    GigSmart
                  </Text>,
                  <Text>
                    Have support that clearly shows Organization information and
                    evidence of direct payment or hire within 90 days of the
                    last engagement with the Organization through GigSmart
                  </Text>
                )}
              </List>
            </ContentArea>
            {DirectPaymentsOnly.select(
              <Text variant="note" color="neutral">
                Please Note: The Direct Payment Bonus is only available to
                Workers who were directly paid outside of the app or brought on
                as a non-W2 employee for the Organization on or after January 1,{" "}
                {previousYear}.
              </Text>,
              <Text variant="note" color="neutral">
                Please Note: The Direct Payment & Hire Bonus is only available
                to Workers who have been directly paid or hired on or after
                January 1, {previousYear}.
              </Text>
            )}
            {DirectPaymentsOnly.isEnabled() && (
              <HighlightedReminder header="You are not eligible for the bonus if you were hired for a W2 full or part-time position with the Organization. Congratulations on your new role!" />
            )}
            <Button
              testID="about-direct-hires-btn"
              variant="clear"
              size="small"
              icon="external-link"
              label={DirectPaymentsOnly.select(
                "Learn More About Direct Payments",
                "Learn About Direct Payments & Hires"
              )}
              onPress={handleLinkPress}
            />
          </Stack>
        </ContentArea>
      </CollapsibleContainer>
    </CollapsibleRow>
  );
};

export default createRelayFragmentContainer<DirectHirePaymentCollapsibleCard_Worker$key>(
  graphql`
    fragment DirectHirePaymentCollapsibleCard_Worker on Worker {
      directHireBonus
    }
  `,
  ({ directHireBonus }) => {
    return <DirectPaymentCollapsibleCard directHireBonus={directHireBonus} />;
  }
);
