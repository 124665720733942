/**
 * @generated SignedSource<<8ccbde37831532ddec1f6040b4a09ca7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type EngagementTimesheetPaymentStyle = "CALCULATED" | "FIXED_AMOUNT" | "FIXED_HOURS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type timesheetEditHooksReset_engagement$data = {
  readonly billingInfo: {
    readonly billableDuration: string;
    readonly totalDue: string;
    readonly totalServiceFee: {
      readonly minimumAmount: string | null | undefined;
      readonly percentageRate: number;
    };
  } | null | undefined;
  readonly endsAt: string | null | undefined;
  readonly id: string;
  readonly startsAt: string | null | undefined;
  readonly timesheet: {
    readonly id: string;
    readonly paymentStyle: EngagementTimesheetPaymentStyle;
    readonly totalBreakDuration: string | null | undefined;
    readonly totalDurationWorked: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "timesheetEditHooksReset_engagement";
};
export type timesheetEditHooksReset_engagement$key = {
  readonly " $data"?: timesheetEditHooksReset_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"timesheetEditHooksReset_engagement">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "timesheetEditHooksReset_engagement"
};

(node as any).hash = "b8edbb130f4e2b6c1ff790a67451ba1f";

export default node;
