import ShareScreen from "@gigsmart/isomorphic-shared/app/share-screen";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  FlagsDebug,
  FlagsDisable,
  FlagsEnable
} from "@gigsmart/isomorphic-shared/feature";
import { UserRestrictions } from "@gigsmart/isomorphic-shared/restrictions";
import { createRootStackNavigator, defaultResetHome } from "@gigsmart/kaizoku";
import { AppLayout } from "@gigsmart/seibutsu/layouts/AppLayout";
import ViewerTopNav from "@gigsmart/seibutsu/nav/ViewerTopNav";
import WebLock from "@gigsmart/seibutsu/navigation/WebLock";
import WorkerPositionCheck from "@gigsmart/seibutsu/positions/WorkerPositionCheck";
import AboutScreen from "@gigsmart/seibutsu/shared/AboutScreen";
import CorrectAppCheck from "@gigsmart/seibutsu/shared/Auth/CorrectAppCheck";
import LegalScreen from "@gigsmart/seibutsu/shared/LegalScreen";
import SupportScreen from "@gigsmart/seibutsu/shared/SupportScreen";
import UserDocumentConsentCheck from "@gigsmart/seibutsu/user-consent/UserDocumentConsentCheck";
import useUtmUserValue from "@gigsmart/seibutsu/utils/useUtmUserValue";
import React from "react";
import WorkerNotificationHandler from "../app/WorkerNotificationHandler";
import LocationLogs from "../app/location-debug";
import { useOnboardingCheck } from "../app/onboarding-check";
import { useNoShowCheck } from "../app/useNoShowCheck";
import { GeolocationProvider } from "../geolocation";
import OfferedGigTopSection from "../offered-gig/offered-gig-top-section";
import { NotFoundScreen } from "./404/NotFoundScreen";
import { useWorkerMenu } from "./menu";
import { useWorkerRoutes } from "./routes";
import type { WorkerParamList } from "./types";

const Nav = createRootStackNavigator<WorkerParamList>();
const ALLOWED_WEB_PATHS = [
  "/reset-password",
  "/login/",
  "/confirm-registration",
  "/legal/"
];

export const WorkerRoot = function WorkerRoot() {
  const isAuthenticated = !!useCurrentUser()?.id;
  const { hasFinishedOnboarding } = useOnboardingCheck();
  const menuItems = useWorkerMenu({
    isAuthenticated,
    hasFinishedOnboarding
  });

  const routes = useWorkerRoutes({
    Nav,
    hasFinishedOnboarding,
    isAuthenticated
  });

  useUtmUserValue();
  useNoShowCheck();

  return (
    <WebLock allowedPaths={ALLOWED_WEB_PATHS}>
      <CorrectAppCheck>
        <UserDocumentConsentCheck enabled={hasFinishedOnboarding}>
          <WorkerNotificationHandler />
          <WorkerPositionCheck enabled={hasFinishedOnboarding}>
            <GeolocationProvider hasFinishedOnboarding={hasFinishedOnboarding}>
              <Nav.Navigator
                LayoutComponent={AppLayout}
                stickyHeader={
                  <>
                    <UserRestrictions />
                    <OfferedGigTopSection />
                  </>
                }
                menuItems={menuItems}
                screenOptions={() => ({
                  NavRightAccessory: hasFinishedOnboarding
                    ? ViewerTopNav
                    : null,
                  onLogoPress: () => defaultResetHome()
                })}
              >
                {/* CLOSED ROUTES */}
                {routes}

                {/* PUBLIC ROUTES */}
                <Nav.Screen name="Support" component={SupportScreen} />
                <Nav.Screen name="Share" component={ShareScreen} />
                <Nav.Screen name="Legal" component={LegalScreen} />
                <Nav.Screen name="About" component={AboutScreen} />
                <Nav.Screen name="FeatureFlags" component={FlagsDebug} />
                <Nav.Screen name="EnableFeatureFlags" component={FlagsEnable} />
                <Nav.Screen
                  name="DisableFeatureFlags"
                  component={FlagsDisable}
                />
                <Nav.Screen name="LocationLogs" component={LocationLogs} />
                <Nav.Screen name="NotFound" component={NotFoundScreen} />
              </Nav.Navigator>
            </GeolocationProvider>
          </WorkerPositionCheck>
        </UserDocumentConsentCheck>
      </CorrectAppCheck>
    </WebLock>
  );
};
