/**
 * @generated SignedSource<<0950a56ee726205a11e5972eaf17674a>>
 * @relayHash f5822ce10d2d5defdfba6c3daebb4bb7
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:7xBS0qFkVmmJu9TaVyXnIiCivfAjKI7Qy6Y8NCbEymQ

import { ConcreteRequest } from 'relay-runtime';
export type CancellationReasonExcuseDisposition = "EXCUSED" | "UNEXCUSED" | "%future added value";
export type CancellationReasonType = "CANCELED" | "DID_NOT_WORK" | "ISSUE_WITH_WORKER" | "NO_LONGER_AVAILABLE" | "RECEIVED_ANOTHER_OFFER" | "REPORT_NO_SHOW" | "REQUESTER_UNRESPONSIVE" | "UNABLE_TO_FINALIZE" | "UNSPECIFIED" | "%future added value";
export type ChargeStatus = "CANCELED" | "CAPTURED" | "EXPIRED" | "FAILED" | "PENDING" | "UNCAPTURED" | "%future added value";
export type DistanceUnit = "KILOMETERS" | "METERS" | "MILES" | "%future added value";
export type EngagementCommitmentResponse = "ACCEPT" | "DECLINE" | "PENDING" | "TIMEOUT" | "%future added value";
export type EngagementDisputeDisposition = "APPROVED" | "REJECTED" | "WITHDRAWN" | "%future added value";
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type EngagementWorkerLocationStatus = "INSIDE_GEOFENCE" | "NOT_TRACKED" | "OUTSIDE_GEOFENCE" | "%future added value";
export type GigAddonType = "BACKGROUND_CHECKS" | "DIRECT_PAYMENT" | "DRUG_SCREENS" | "EOR_WORKERS" | "EXTEND_PROJECT_GIG" | "GENERAL_LIABILITY_INSURANCE" | "MOTOR_VEHICLE_CHECKS" | "OCCUPATIONAL_ACCIDENT_INSURANCE" | "ONE_WEEK_BOOST" | "SHIFT_GIG_PAYMENTS" | "UNLOCK_PROJECT_GIG" | "%future added value";
export type GigAudienceType = "FAVORITES" | "PREVIOUSLY_WORKED" | "PUBLIC" | "WORKER_GROUP" | "%future added value";
export type GigAutoHireStrategy = "APPLICANTS" | "BROAD" | "NONE" | "%future added value";
export type GigPaymentStatus = "AWAITING_PAYMENT" | "CANCELED" | "FAILED_PAYMENT" | "NONE" | "RECONCILED" | "%future added value";
export type GigReportingType = "HIDDEN" | "MANAGED_SERVICES" | "NO_PREAUTH" | "STANDARD" | "VARIABLE_BILLING" | "%future added value";
export type GigStateName = "ACTIVE" | "CANCELED" | "COMPLETED" | "DRAFT" | "EXPIRED" | "INACTIVE" | "INCOMPLETE" | "IN_PROGRESS" | "PENDING_REVIEW" | "RECONCILED" | "UPCOMING" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type ScheduledEventAction = "APPLICATION_TIMEOUT" | "ARCHIVE_TOPIC" | "AUTO_OFFER_WORKERS" | "BATCH_CONFIRM" | "CANCEL_GIG" | "CANCEL_OUTSTANDING_OFFERS" | "CHECK_COMMITMENT" | "CHECK_CONFIRMATION" | "CHECK_PREAUTH" | "CLEAN" | "CREATE_COMMITMENT" | "ELAPSED_START" | "END_GIG" | "EXCEED_LATEST_ARRIVAL_TIME" | "EXPIRE" | "EXPIRING_SOON" | "FIFTEEN_MINUTES_BEFORE_GIG_END_WARNING" | "FIVE_MINUTES_BEFORE_WARNING" | "GIG_EXPIRATION" | "GIG_HAS_ENDED_WARNING" | "GIG_PICKUP_WINDOW_AVAILABLE" | "JOB_EXPIRING" | "MISS_GIG" | "NEARING_GIG_END_WARNING" | "ONE_DAY_BEFORE_GIG_WARNING" | "ONE_DAY_BEFORE_WARNING" | "OUTSTANDING_DISPUTE_WARNING" | "PREAUTHORIZE" | "RECALCULATE_STATE" | "RECONCILE" | "RECONCILE_TIMESHEET" | "REJECT_DISPUTE" | "REQUESTER_WORKER_INACTIVE_WARNING" | "SIXTY_MINUTES_BEFORE_GIG_WARNING" | "SIXTY_MINUTES_BEFORE_WARNING" | "THIRTY_MINUTES_BEFORE_GIG_WARNING" | "THIRTY_MINUTES_BEFORE_WARNING" | "THREE_HOURS_BEFORE_GIG_WARNING" | "THREE_HOURS_BEFORE_WARNING" | "TIMEOUT" | "TIMEOUT_APPLICANTS" | "TIMEOUT_CONFIRMATION" | "WORKER_APPROVE_TIMESHEET_EXPIRATION" | "WORKER_ARRIVING_WARNING" | "WORKER_AWAITING_START_WARNING" | "WORKER_INACTIVE_WARNING" | "WORKER_NOT_VERIFIED" | "WORKER_VERIFIED" | "%future added value";
export type ScheduledEventType = "BATCH_ENGAGEMENT_EVENT" | "DIRECT_HIRE_EVENT" | "GIG_LOTTERY_TIMED_EVENT" | "GIG_PREAUTHORIZATION_EVENT" | "GIG_SERIES_CONFIRMATION_CHECK" | "GIG_SERIES_CONFIRMATION_TIMEOUT" | "GIG_SERIES_EVENT" | "GIG_SERIES_TIMED_EVENT" | "GIG_STATE_EVENT" | "GIG_TIMED_ALERT" | "GIG_TIMED_EVENT" | "GIG_WORKER_ALERT" | "GIG_WORKER_EVENT" | "JOB_POSTING_EVENT" | "%future added value";
export type UserType = "ADMIN" | "ORGANIZATION_APPLICATION" | "ORGANIZATION_REQUESTER" | "REQUESTER" | "SYSTEM" | "WORKER" | "%future added value";
export type WorkerAttendanceStatus = "LATE_ARRIVAL" | "LATE_CANCELLATION" | "NO_SHOW" | "ON_TIME" | "%future added value";
export type WorkerTransportationState = "MOTORCYCLE" | "SEDAN" | "SUV" | "TRUCK" | "VAN" | "%future added value";
export type CqlFilterGigInput = {
  _and?: ReadonlyArray<CqlFilterGigInput> | null | undefined;
  _not?: CqlFilterGigInput | null | undefined;
  _or?: ReadonlyArray<CqlFilterGigInput> | null | undefined;
  acceptsTips?: CqlOpBooleanInput | null | undefined;
  actionScore?: CqlOpFloatInput | null | undefined;
  actualStartsAt?: CqlOpDatetimeInput | null | undefined;
  address?: CqlOpStringInput | null | undefined;
  allSlotsFilled?: CqlOpBooleanInput | null | undefined;
  anyChargeStatus?: CqlEnumChargeStatusInput | null | undefined;
  anyEngagementStateName?: CqlEnumEngagementStateNameInput | null | undefined;
  assignedAdminId?: CqlOpIdInput | null | undefined;
  assignedRepType?: CqlOpStringInput | null | undefined;
  audienceType?: CqlEnumGigAudienceTypeInput | null | undefined;
  autoHireStrategy?: CqlEnumGigAutoHireStrategyInput | null | undefined;
  autoOfferWorkers?: CqlOpBooleanInput | null | undefined;
  basedOnGigId?: CqlOpIdInput | null | undefined;
  boosted?: CqlOpBooleanInput | null | undefined;
  boostedUntil?: CqlOpDatetimeInput | null | undefined;
  chargeAmount?: CqlOpFloatInput | null | undefined;
  crisisId?: CqlOpIdInput | null | undefined;
  currentEngagementStateName?: CqlEnumEngagementStateNameInput | null | undefined;
  currentStateName?: CqlEnumGigStateNameInput | null | undefined;
  description?: CqlOpStringInput | null | undefined;
  endsAt?: CqlOpDatetimeInput | null | undefined;
  engagementCommitmentResponse?: CqlEnumEngagementCommitmentResponseInput | null | undefined;
  engagementsLastTransitionedAt?: CqlOpDatetimeInput | null | undefined;
  estimatedMileage?: CqlOpFloatInput | null | undefined;
  gigAddonType?: CqlEnumGigAddonTypeInput | null | undefined;
  gigScore?: CqlOpFloatInput | null | undefined;
  gigSeriesId?: CqlOpIdInput | null | undefined;
  gigType?: CqlEnumGigTypeInput | null | undefined;
  hubspotDealId?: CqlOpStringInput | null | undefined;
  id?: CqlOpIdInput | null | undefined;
  impressionCount?: CqlOpIntegerInput | null | undefined;
  insertedAt?: CqlOpDatetimeInput | null | undefined;
  isAsap?: CqlOpBooleanInput | null | undefined;
  isAssigned?: CqlOpBooleanInput | null | undefined;
  isClosed?: CqlOpBooleanInput | null | undefined;
  isPosted?: CqlOpBooleanInput | null | undefined;
  isRequesterApproved?: CqlOpBooleanInput | null | undefined;
  lastTransitionedAt?: CqlOpDatetimeInput | null | undefined;
  latestChargeStatus?: CqlEnumChargeStatusInput | null | undefined;
  location?: CqlOpCoordinatesInput | null | undefined;
  name?: CqlOpStringInput | null | undefined;
  organizationId?: CqlOpIdInput | null | undefined;
  organizationLocationId?: CqlOpIdInput | null | undefined;
  organizationLocationName?: CqlOpStringInput | null | undefined;
  organizationName?: CqlOpStringInput | null | undefined;
  organizationPositionId?: CqlOpIdInput | null | undefined;
  organizationPositionName?: CqlOpStringInput | null | undefined;
  payRate?: CqlOpFloatInput | null | undefined;
  paymentMethodId?: CqlOpStringInput | null | undefined;
  paymentStatus?: CqlEnumGigPaymentStatusInput | null | undefined;
  pickupEligible?: CqlOpBooleanInput | null | undefined;
  predictiveAutoHire?: CqlOpBooleanInput | null | undefined;
  primaryContactId?: CqlOpIdInput | null | undefined;
  publishedAt?: CqlOpDatetimeInput | null | undefined;
  reportingType?: CqlEnumGigReportingTypeInput | null | undefined;
  requesterId?: CqlOpIdInput | null | undefined;
  requesterName?: CqlOpStringInput | null | undefined;
  skillId?: CqlOpIdInput | null | undefined;
  slotDelta?: CqlOpIntegerInput | null | undefined;
  slots?: CqlOpIntegerInput | null | undefined;
  slotsFilled?: CqlOpIntegerInput | null | undefined;
  startsAt?: CqlOpDatetimeInput | null | undefined;
  territoryId?: CqlOpIdInput | null | undefined;
  thirdPartyApplicationsCount?: CqlOpIntegerInput | null | undefined;
  updatedAt?: CqlOpDatetimeInput | null | undefined;
  valueScore?: CqlOpFloatInput | null | undefined;
  workerGroupId?: CqlOpIdInput | null | undefined;
  workerId?: CqlOpIdInput | null | undefined;
  workerName?: CqlOpStringInput | null | undefined;
};
export type CqlOpBooleanInput = {
  _eq?: boolean | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: boolean | null | undefined;
};
export type CqlOpFloatInput = {
  _eq?: number | null | undefined;
  _gt?: number | null | undefined;
  _gte?: number | null | undefined;
  _isNull?: boolean | null | undefined;
  _lt?: number | null | undefined;
  _lte?: number | null | undefined;
  _neq?: number | null | undefined;
};
export type CqlOpDatetimeInput = {
  _eq?: string | null | undefined;
  _gt?: string | null | undefined;
  _gte?: string | null | undefined;
  _isNull?: boolean | null | undefined;
  _lt?: string | null | undefined;
  _lte?: string | null | undefined;
  _neq?: string | null | undefined;
};
export type CqlOpStringInput = {
  _eq?: string | null | undefined;
  _ilike?: string | null | undefined;
  _in?: ReadonlyArray<string> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: string | null | undefined;
};
export type CqlEnumChargeStatusInput = {
  _eq?: ChargeStatus | null | undefined;
  _in?: ReadonlyArray<ChargeStatus> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: ChargeStatus | null | undefined;
};
export type CqlEnumEngagementStateNameInput = {
  _eq?: EngagementStateName | null | undefined;
  _in?: ReadonlyArray<EngagementStateName> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: EngagementStateName | null | undefined;
};
export type CqlOpIdInput = {
  _eq?: string | null | undefined;
  _in?: ReadonlyArray<string> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: string | null | undefined;
};
export type CqlEnumGigAudienceTypeInput = {
  _eq?: GigAudienceType | null | undefined;
  _in?: ReadonlyArray<GigAudienceType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: GigAudienceType | null | undefined;
};
export type CqlEnumGigAutoHireStrategyInput = {
  _eq?: GigAutoHireStrategy | null | undefined;
  _in?: ReadonlyArray<GigAutoHireStrategy> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: GigAutoHireStrategy | null | undefined;
};
export type CqlEnumGigStateNameInput = {
  _eq?: GigStateName | null | undefined;
  _in?: ReadonlyArray<GigStateName> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: GigStateName | null | undefined;
};
export type CqlEnumEngagementCommitmentResponseInput = {
  _eq?: EngagementCommitmentResponse | null | undefined;
  _in?: ReadonlyArray<EngagementCommitmentResponse> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: EngagementCommitmentResponse | null | undefined;
};
export type CqlEnumGigAddonTypeInput = {
  _eq?: GigAddonType | null | undefined;
  _in?: ReadonlyArray<GigAddonType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: GigAddonType | null | undefined;
};
export type CqlEnumGigTypeInput = {
  _eq?: GigType | null | undefined;
  _in?: ReadonlyArray<GigType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: GigType | null | undefined;
};
export type CqlOpIntegerInput = {
  _eq?: number | null | undefined;
  _gt?: number | null | undefined;
  _gte?: number | null | undefined;
  _in?: ReadonlyArray<number> | null | undefined;
  _isNull?: boolean | null | undefined;
  _lt?: number | null | undefined;
  _lte?: number | null | undefined;
  _neq?: number | null | undefined;
};
export type CqlOpCoordinatesInput = {
  _eq?: Coordinates | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: Coordinates | null | undefined;
  _stDwithin?: CqlOpDwithinInput | null | undefined;
  _stWithinBoundingBox?: BoundingBoxCoordinates | null | undefined;
};
export type Coordinates = {
  latitude: number;
  longitude: number;
};
export type CqlOpDwithinInput = {
  coordinates: Coordinates;
  distanceUnit?: DistanceUnit | null | undefined;
  radius: number;
};
export type BoundingBoxCoordinates = {
  northeastCoordinates: Coordinates;
  southwestCoordinates: Coordinates;
};
export type CqlEnumGigPaymentStatusInput = {
  _eq?: GigPaymentStatus | null | undefined;
  _in?: ReadonlyArray<GigPaymentStatus> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: GigPaymentStatus | null | undefined;
};
export type CqlEnumGigReportingTypeInput = {
  _eq?: GigReportingType | null | undefined;
  _in?: ReadonlyArray<GigReportingType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: GigReportingType | null | undefined;
};
export type CqlFilterEngagementInput = {
  _and?: ReadonlyArray<CqlFilterEngagementInput> | null | undefined;
  _not?: CqlFilterEngagementInput | null | undefined;
  _or?: ReadonlyArray<CqlFilterEngagementInput> | null | undefined;
  actualStartsAt?: CqlOpDatetimeInput | null | undefined;
  anyEngagementDisputeDisposition?: CqlEnumEngagementDisputeDispositionInput | null | undefined;
  anyScheduledEventAction?: CqlEnumScheduledEventActionInput | null | undefined;
  anyScheduledEventType?: CqlEnumScheduledEventTypeInput | null | undefined;
  anyStateAction?: CqlEnumEngagementStateActionInput | null | undefined;
  anyStateName?: CqlEnumEngagementStateNameInput | null | undefined;
  anyStateOperationKey?: CqlOpStringInput | null | undefined;
  assignedAdminId?: CqlOpIdInput | null | undefined;
  attendanceStatus?: CqlEnumWorkerAttendanceStatusInput | null | undefined;
  audienceMembership?: CqlEnumGigAudienceTypeInput | null | undefined;
  averageWorkerRating?: CqlOpFloatInput | null | undefined;
  calculatedStart?: CqlOpDatetimeInput | null | undefined;
  cancellationReasonType?: CqlEnumCancellationReasonTypeInput | null | undefined;
  commitmentResponse?: CqlEnumEngagementCommitmentResponseInput | null | undefined;
  completedGigCount?: CqlOpIntegerInput | null | undefined;
  currentPredictionEnded?: CqlOpFloatInput | null | undefined;
  currentStateAction?: CqlEnumEngagementStateActionInput | null | undefined;
  currentStateName?: CqlEnumEngagementStateNameInput | null | undefined;
  currentStateOperationKey?: CqlOpStringInput | null | undefined;
  currentStateTransitionedAt?: CqlOpDatetimeInput | null | undefined;
  currentStateTransitionedBy?: CqlOpIdInput | null | undefined;
  currentStateTransitionedByType?: CqlEnumUserTypeInput | null | undefined;
  endsAt?: CqlOpDatetimeInput | null | undefined;
  estimatedAmount?: CqlOpFloatInput | null | undefined;
  excusable?: CqlOpBooleanInput | null | undefined;
  excusableUntil?: CqlOpDatetimeInput | null | undefined;
  excuseDisposition?: CqlEnumCancellationReasonExcuseDispositionInput | null | undefined;
  gigAllSlotsFilled?: CqlOpBooleanInput | null | undefined;
  gigEndsAt?: CqlOpDatetimeInput | null | undefined;
  gigId?: CqlOpIdInput | null | undefined;
  gigName?: CqlOpStringInput | null | undefined;
  gigSlots?: CqlOpIntegerInput | null | undefined;
  gigStateName?: CqlEnumGigStateNameInput | null | undefined;
  gigType?: CqlEnumGigTypeInput | null | undefined;
  hasDisputes?: CqlOpBooleanInput | null | undefined;
  hasPayout?: CqlOpBooleanInput | null | undefined;
  hasRequesterReview?: CqlOpBooleanInput | null | undefined;
  id?: CqlOpIdInput | null | undefined;
  insertedAt?: CqlOpDatetimeInput | null | undefined;
  isAsap?: CqlOpBooleanInput | null | undefined;
  lastKnownWorkerLocation?: CqlOpCoordinatesInput | null | undefined;
  locationStatus?: CqlEnumEngagementWorkerLocationStatusInput | null | undefined;
  organizationId?: CqlOpIdInput | null | undefined;
  organizationLocationId?: CqlOpIdInput | null | undefined;
  organizationName?: CqlOpStringInput | null | undefined;
  organizationPositionId?: CqlOpIdInput | null | undefined;
  organizationPositionName?: CqlOpStringInput | null | undefined;
  paidByRequester?: CqlOpBooleanInput | null | undefined;
  payRate?: CqlOpFloatInput | null | undefined;
  publishedAt?: CqlOpDatetimeInput | null | undefined;
  ratingOfRequester?: CqlOpIntegerInput | null | undefined;
  ratingOfWorker?: CqlOpIntegerInput | null | undefined;
  reportingType?: CqlOpDatetimeInput | null | undefined;
  requesterId?: CqlOpIdInput | null | undefined;
  requesterName?: CqlOpStringInput | null | undefined;
  startsAt?: CqlOpDatetimeInput | null | undefined;
  workerBelongsToGroup?: CqlOpBooleanInput | null | undefined;
  workerCanApproveTimesheet?: CqlOpBooleanInput | null | undefined;
  workerDistance?: CqlOpFloatInput | null | undefined;
  workerEmail?: CqlOpStringInput | null | undefined;
  workerEstimatedLate?: CqlOpBooleanInput | null | undefined;
  workerFullName?: CqlOpStringInput | null | undefined;
  workerGroupId?: CqlOpIdInput | null | undefined;
  workerId?: CqlOpIdInput | null | undefined;
  workerIsFavorite?: CqlOpBooleanInput | null | undefined;
  workerLocationReportedAt?: CqlOpDatetimeInput | null | undefined;
  workerMobileNumber?: CqlOpStringInput | null | undefined;
  workerName?: CqlOpStringInput | null | undefined;
  workerOver18?: CqlOpBooleanInput | null | undefined;
  workerTransportationState?: CqlEnumWorkerTransportationStateInput | null | undefined;
};
export type CqlEnumEngagementDisputeDispositionInput = {
  _eq?: EngagementDisputeDisposition | null | undefined;
  _in?: ReadonlyArray<EngagementDisputeDisposition> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: EngagementDisputeDisposition | null | undefined;
};
export type CqlEnumScheduledEventActionInput = {
  _eq?: ScheduledEventAction | null | undefined;
  _in?: ReadonlyArray<ScheduledEventAction> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: ScheduledEventAction | null | undefined;
};
export type CqlEnumScheduledEventTypeInput = {
  _eq?: ScheduledEventType | null | undefined;
  _in?: ReadonlyArray<ScheduledEventType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: ScheduledEventType | null | undefined;
};
export type CqlEnumEngagementStateActionInput = {
  _eq?: EngagementStateAction | null | undefined;
  _in?: ReadonlyArray<EngagementStateAction> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: EngagementStateAction | null | undefined;
};
export type CqlEnumWorkerAttendanceStatusInput = {
  _eq?: WorkerAttendanceStatus | null | undefined;
  _in?: ReadonlyArray<WorkerAttendanceStatus> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: WorkerAttendanceStatus | null | undefined;
};
export type CqlEnumCancellationReasonTypeInput = {
  _eq?: CancellationReasonType | null | undefined;
  _in?: ReadonlyArray<CancellationReasonType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: CancellationReasonType | null | undefined;
};
export type CqlEnumUserTypeInput = {
  _eq?: UserType | null | undefined;
  _in?: ReadonlyArray<UserType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: UserType | null | undefined;
};
export type CqlEnumCancellationReasonExcuseDispositionInput = {
  _eq?: CancellationReasonExcuseDisposition | null | undefined;
  _in?: ReadonlyArray<CancellationReasonExcuseDisposition> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: CancellationReasonExcuseDisposition | null | undefined;
};
export type CqlEnumEngagementWorkerLocationStatusInput = {
  _eq?: EngagementWorkerLocationStatus | null | undefined;
  _in?: ReadonlyArray<EngagementWorkerLocationStatus> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: EngagementWorkerLocationStatus | null | undefined;
};
export type CqlEnumWorkerTransportationStateInput = {
  _eq?: WorkerTransportationState | null | undefined;
  _in?: ReadonlyArray<WorkerTransportationState> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: WorkerTransportationState | null | undefined;
};
export type DeleteAccountPrimaryContactGigsQuery$variables = {
  requesterFilter: CqlFilterGigInput;
  userId: string;
  workerFilter: CqlFilterEngagementInput;
};
export type DeleteAccountPrimaryContactGigsQuery$data = {
  readonly member: {
    readonly __typename: string;
    readonly displayName?: string | null | undefined;
    readonly engagements?: {
      readonly totalCount: number;
    } | null | undefined;
    readonly gigs?: {
      readonly totalCount: number;
    } | null | undefined;
    readonly id: string;
    readonly requester?: {
      readonly gigs: {
        readonly totalCount: number;
      } | null | undefined;
      readonly id: string;
    };
  } | null | undefined;
};
export type DeleteAccountPrimaryContactGigsQuery = {
  response: DeleteAccountPrimaryContactGigsQuery$data;
  variables: DeleteAccountPrimaryContactGigsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "requesterFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workerFilter"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": [
    (v4/*: any*/),
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "requesterFilter"
    }
  ],
  "concreteType": "GigsConnection",
  "kind": "LinkedField",
  "name": "gigs",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = [
  {
    "alias": "member",
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "userId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": [
              (v4/*: any*/),
              {
                "kind": "Variable",
                "name": "where",
                "variableName": "workerFilter"
              }
            ],
            "concreteType": "EngagementsConnection",
            "kind": "LinkedField",
            "name": "engagements",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "type": "Worker",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v6/*: any*/)
        ],
        "type": "Requester",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Requester",
            "kind": "LinkedField",
            "name": "requester",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "type": "OrganizationRequester",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteAccountPrimaryContactGigsQuery",
    "selections": (v7/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeleteAccountPrimaryContactGigsQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "id": "qpid:7xBS0qFkVmmJu9TaVyXnIiCivfAjKI7Qy6Y8NCbEymQ",
    "metadata": {},
    "name": "DeleteAccountPrimaryContactGigsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "3e261d17ef0c2db99bb849381c2900fa";

export default node;
