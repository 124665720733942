import { GridNull, Icon } from "@gigsmart/atorasu";
import { WorkerAttendanceWarnings } from "@gigsmart/feature-flags";
import { type NavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import AttendanceOverviewDetails from "../attendance/AttendanceOverviewDetails";
import type { SharedParamList } from "../navigation/types";
import HomeScreenCard from "../worker/HomescreenCard";
import type { AttendanceOverviewCard_worker$key } from "./__generated__/AttendanceOverviewCard_worker.graphql";

export function AttendanceOverviewCard({
  gigStats,
  result
}: FragmentContainerInnerComponentProps<AttendanceOverviewCard_worker$key>) {
  const navigation =
    useNavigation<
      NavigationProp<SharedParamList, "ProfileAttendanceOverview">
    >();
  const totalEngagementsScheduled = gigStats?.engagementsScheduled ?? 0;

  if (totalEngagementsScheduled < 1 || WorkerAttendanceWarnings.isDisabled()) {
    return <GridNull />;
  }

  return (
    <HomeScreenCard
      headerTitle="Your Attendance Overview - Last 60 Days"
      headerIcon="user-clock"
      testID="attendance-overview-card"
      onHeaderPress={() => navigation.navigate("ProfileAttendanceOverview", {})}
      action={
        <Icon
          name="chevron-right"
          size="small"
          color="primary"
          variant="solid"
        />
      }
    >
      <AttendanceOverviewDetails fragmentRef={result} />
    </HomeScreenCard>
  );
}

export default createRelayFragmentContainer<AttendanceOverviewCard_worker$key>(
  graphql`
    fragment AttendanceOverviewCard_worker on Worker @argumentDefinitions(
      startDate: { type: "DateTime" }
      endDate: { type: "DateTime" }
    ) {
      gigStats {
        engagementsScheduled
      }
      ...AttendanceOverviewDetails_worker @arguments(
        startDate: $startDate
        endDate: $endDate
      )
    }
  `,
  AttendanceOverviewCard
);
