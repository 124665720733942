import {
  ContentArea,
  Screen,
  Surface,
  Text,
  ThumbnailRow
} from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import AttendanceFilteredEngagementList, {
  getQuery as computeEngagementsFilter
} from "@gigsmart/seibutsu/attendance/AttendanceFilteredEngagementList";
import type { WorkerAttendanceStatus } from "@gigsmart/seibutsu/attendance/types";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import type { requesterAttendanceFilteredShiftsScreenQuery } from "./__generated__/requesterAttendanceFilteredShiftsScreenQuery.graphql";
type Props = AppScreenProps<
  WorkerParamList,
  "OrganizationAttendanceFilteredShifts"
>;

const titleMap: Partial<Record<WorkerAttendanceStatus, string>> = {
  LATE_ARRIVAL: "Late Arrivals",
  LATE_CANCELLATION: "Late Cancellations",
  NO_SHOW: "No Shows",
  ON_TIME: "On-Time Arrivals"
};

export default createSuspendedQueryContainer<
  requesterAttendanceFilteredShiftsScreenQuery,
  Props
>(
  function RequesterAttendanceFilteredShiftsScreen({ route, response }) {
    const { attendanceStatus, attendanceOverviewDays, orgId, allShifts } =
      route.params ?? {};

    const organization = response?.node;
    const viewer = response?.viewer;
    const shiftsCount = viewer?.shiftsCount?.totalCount ?? 0;

    const getTitle = (status: WorkerAttendanceStatus): string => {
      if (allShifts) return `${pluralize(shiftsCount, "Shift")} Worked`;

      const shiftType = titleMap[status] ?? "";
      return `${pluralize(shiftsCount, shiftType)}`;
    };

    return (
      <Screen
        testID="attendance-filtered-shifts-screen"
        header={
          <Surface>
            <ContentArea size="medium" variant="medium">
              <ThumbnailRow
                spacing="medium"
                size="small"
                icon="building"
                uri={organization?.logoUrl}
                title={organization?.name}
                initials={organization?.name?.[0]}
                titleNumberOfLines={1}
              >
                <Text variant="note" color="black">
                  {getTitle(attendanceStatus)} - {attendanceOverviewDays}
                </Text>
              </ThumbnailRow>
            </ContentArea>
          </Surface>
        }
      >
        <AttendanceFilteredEngagementList
          workerId={viewer?.id ?? ""}
          attendanceStatus={attendanceStatus}
          attendanceOverviewDays={attendanceOverviewDays}
          organizationId={orgId}
          allShifts={allShifts}
        />
      </Screen>
    );
  },
  {
    query: graphql`
      query requesterAttendanceFilteredShiftsScreenQuery($id: ID!, $engagementsFilter: CqlFilterEngagementInput) {
        node(id: $id) {
          ... on Organization {
            id
            name
            logoUrl
          }
        }
        viewer {
          ... on Worker {
            id
            shiftsCount: engagements(first: 0, where: $engagementsFilter) {
              totalCount
            }
          }
        }
      }
    `,
    variables: ({ route }: Props) => ({
      id: route.params?.orgId ?? "",
      engagementsFilter: computeEngagementsFilter({
        ...route.params,
        organizationId: route.params?.orgId
      })
    })
  }
);
