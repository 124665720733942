import type { LinkingOptions, ParamListBase } from "@gigsmart/kaizoku";
import { Linking } from "react-native";

const INITIAL_URL_GETTERS = [
  () => process.env.INITIAL_URL ?? null,
  () => Linking.getInitialURL().catch(() => null)
];

export const getInitialLink = async () => {
  let initialUrl: string | null = null;
  for (const getter of INITIAL_URL_GETTERS) initialUrl ||= await getter();

  return initialUrl;
};

export const withDeepLinking = <T extends ParamListBase>(
  linking: LinkingOptions<T>
): LinkingOptions<T> => linking;
