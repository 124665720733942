/**
 * @generated SignedSource<<9a91321f36a713c6bf03d3533d204fdb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AttendanceOverviewDetails_worker$data = {
  readonly attendanceStats: {
    readonly lateArrival: number;
    readonly lateCancellationExcused: number;
    readonly lateCancellationTotal: number;
    readonly lateCancellationUnexcused: number;
    readonly noShowExcused: number;
    readonly noShowTotal: number;
    readonly noShowUnexcused: number;
    readonly onTime: number;
  } | null | undefined;
  readonly gigStats: {
    readonly engagementsEnded: number;
  } | null | undefined;
  readonly " $fragmentType": "AttendanceOverviewDetails_worker";
};
export type AttendanceOverviewDetails_worker$key = {
  readonly " $data"?: AttendanceOverviewDetails_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"AttendanceOverviewDetails_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AttendanceOverviewDetails_worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerGigStats",
      "kind": "LinkedField",
      "name": "gigStats",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "engagementsEnded",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "end",
          "variableName": "endDate"
        },
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        },
        {
          "kind": "Variable",
          "name": "start",
          "variableName": "startDate"
        }
      ],
      "concreteType": "WorkerAttendanceStats",
      "kind": "LinkedField",
      "name": "attendanceStats",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lateArrival",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lateCancellationTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lateCancellationExcused",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lateCancellationUnexcused",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "noShowTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "noShowExcused",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "noShowUnexcused",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "2ce744b8c9199f65761661459261dbc0";

export default node;
