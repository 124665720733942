import { Normalizer } from "@gigsmart/fomu";
import React from "react";
import { Dimensions, View } from "react-native";
import Card from "../container/card";
import { ListSeparator } from "../list";
import BaseRow from "../row/base-row";
import { Spacer } from "../spacer";
import { type ViewStyleProp, useStyles } from "../style";
import { OutlineButton } from "../tappable";
import { StyledText } from "../text";
import StyledTextInput from "./styled-text-input";
import InputSubtext from "./styled-text-input/input-subtext";
import { numericKeyboardType } from "./text-utils";

interface Props {
  style?: ViewStyleProp;
  title?: string;
  subtitle?: string;
  rowLabel: string;
  hours: {
    errorMessage?: string | null | undefined;
    showErrors: (() => unknown) | undefined;
    value?: number | null | undefined;
    setValue?: ((v: string) => void) | undefined;
  };
  minutes: {
    errorMessage?: string | null | undefined;
    showErrors: (() => unknown) | undefined;
    value?: number | null | undefined;
    setValue?: ((v: string) => void) | undefined;
  };
  testID?: string;
  handleReset?: () => void;
}

export default ({
  style,
  title,
  subtitle,
  rowLabel,
  hours,
  minutes,
  testID,
  handleReset
}: Props) => {
  const xsmallDevice = Dimensions.get("window").width < 340;
  const { styles } = useStyles(({ units }) => ({
    container: {
      padding: units(4)
    },
    hrsMinsDisplay: {
      flexDirection: "row",
      alignItems: "center"
    },
    inputContainer: {
      width: xsmallDevice ? units(12) : units(16),
      paddingLeft: units(2)
    },
    inputText: {
      maxWidth: units(15),
      paddingRight: units(0)
    },
    resetPadding: {
      paddingTop: units(3)
    },
    timeText: {
      paddingLeft: units(2)
    },
    error: { textAlign: "center" }
  }));
  const hasError = Boolean(hours.errorMessage) || Boolean(minutes.errorMessage);
  const errorMessage = [hours.errorMessage, minutes.errorMessage]
    ?.filter((message) => !!message)
    ?.join(" ");
  return (
    <Card testID={testID} style={[styles.container, style]} noSpacing noMargin>
      {Boolean(title) && (
        <>
          <StyledText bold>{title}</StyledText>
          <Spacer />
        </>
      )}

      {Boolean(subtitle) && (
        <>
          <StyledText>{subtitle}</StyledText>
          <Spacer />
        </>
      )}

      <ListSeparator inset={0} />
      <BaseRow alignItems="center">
        <StyledText color={hasError ? "orange" : undefined}>
          {rowLabel}
        </StyledText>
        <View style={styles.hrsMinsDisplay}>
          <StyledTextInput
            legacyImplementation
            containerStyle={styles.inputContainer}
            inputStyle={styles.inputText}
            keyboardType={numericKeyboardType()}
            maxLength={2}
            minLength={1}
            eventTargetName="Input Estimated Hours"
            testID="estimated-hours"
            error={Boolean(hours.errorMessage)}
            onBlur={hours.showErrors}
            value={String(hours.value)}
            onChangeText={hours.setValue}
            normalize={Normalizer.currencyDigits}
          />
          <StyledText style={styles.timeText}>Hrs</StyledText>
          <StyledTextInput
            legacyImplementation
            containerStyle={styles.inputContainer}
            inputStyle={styles.inputText}
            keyboardType={numericKeyboardType()}
            maxLength={2}
            minLength={1}
            eventTargetName="Input Estimated Minutes"
            testID="estimated-minutes"
            error={Boolean(minutes.errorMessage)}
            onBlur={minutes.showErrors}
            value={String(minutes.value)}
            onChangeText={minutes.setValue}
            normalize={Normalizer.currencyDigits}
          />
          <StyledText style={styles.timeText}>Mins</StyledText>
        </View>
      </BaseRow>
      {hasError && (
        <InputSubtext
          style={styles.error}
          label={errorMessage ?? undefined}
          error
        />
      )}
      {handleReset && (
        <>
          <Spacer />
          <OutlineButton
            icon="redo"
            title={`Remove ${rowLabel}`}
            onPress={handleReset}
          />
        </>
      )}
    </Card>
  );
};
