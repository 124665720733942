import {
  AbsoluteContainer,
  Avatar,
  Column,
  ContentArea,
  Icon,
  ListRow,
  Pressable,
  Row,
  ScreenScroll,
  Spacer,
  Stack,
  StarRating,
  Surface,
  Text,
  Well,
  showImageCarousel,
  useMatchesViewport,
  useStyles
} from "@gigsmart/atorasu";
import { where } from "@gigsmart/biruda";
import { WorkerAttendanceWarnings } from "@gigsmart/feature-flags";
import type { NavigationProp } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import DirectHireActionRow from "@gigsmart/seibutsu/direct-hire/worker/DirectHireActionRow";
import SpiralsImage from "@gigsmart/seibutsu/worker-profile/images/Spirals";
import OrgAttendanceCard from "@gigsmart/seibutsu/worker/Attendance/OrgAttendanceCard";
import BlockOrganizationCollapsibleRow, {
  showWorkerBlockOrg
} from "@gigsmart/seibutsu/worker/WorkerBlockOrganizations/BlockOrganizationCollapsibleRow";
import WorkerBlockedOrganizationReminder, {
  showWorkerBlockedOrganizationReminder
} from "@gigsmart/seibutsu/worker/WorkerBlockOrganizations/WorkerBlockedOrganizationReminder";
import { DateTime } from "luxon";
import React from "react";
import { TouchableOpacity } from "react-native";
import type { WorkerParamList } from "../navigation/types";
import type { requesterQuery } from "./__generated__/requesterQuery.graphql";

// eslint-disable-next-line react/no-unused-prop-types
type Props = {
  gigId: string;
  requesterId: string;
  organizationId: string;
  navigation: NavigationProp<
    WorkerParamList,
    "ProjectRequesterProfile" | "ShiftRequesterProfile" | "BrowseGigRequester"
  >;
};
const now = DateTime.now();

export default createSuspendedQueryContainer<requesterQuery, Props>(
  function GigRequesterScreen({ response, gigId, navigation }) {
    const requester = response?.requester;
    const viewer = response?.viewer;
    const hasOrgRelationship =
      (response?.viewer?.organizations?.totalCount ?? 0) > 0;
    const gig = response?.gig;
    const isShift = gig?.gigType === "PAID";
    const requesterType = getRequesterType(!!gig?.organization);
    const isSm = useMatchesViewport(({ size }) => size.small.down);
    const styles = useStyles(({ getUnits }) => ({
      avatarContainer: {
        backgroundColor: "white",
        padding: getUnits(1.5),
        borderRadius: getUnits(25)
      }
    }));
    const {
      id: organizationId,
      logoUrl,
      name: organizationName,
      industry,
      locality,
      administrativeArea1,
      overallRating: orgRating,
      reviewsReceived: orgReviews,
      description,
      companySize,
      website,
      yearFounded,
      officeType,
      union
    } = (gig ? gig?.organization : requester?.primaryOrganization) ?? {};
    const {
      profilePhoto,
      displayName,
      biography,
      reviewsReceived,
      overallRating
    } = requester ?? {};

    const profilePhotoUrl = profilePhoto?.url ?? "";
    const photoUrl =
      requesterType === "organization" && logoUrl ? logoUrl : profilePhotoUrl;
    const openProfileImage = () => showImageCarousel([photoUrl ?? ""]);
    return (
      <ScreenScroll testID="requester-profile" gap="standard">
        <Surface>
          <AbsoluteContainer top={0} zIndex={0}>
            <Well color="primary" contentVariant="none" size="none">
              <SpiralsImage />
              <Spacer size="medium" />
            </Well>
          </AbsoluteContainer>
          <ContentArea gap="standard" zIndex={9}>
            <Row gap="standard">
              <Column style={styles.avatarContainer}>
                <Pressable
                  onPress={openProfileImage}
                  eventEntityType="profilePicturePressable"
                  eventTargetName="Profile Picture Pressable"
                  testID="profile-picture-pressable"
                >
                  <Avatar {...{ uri: photoUrl }} size="xLarge" />
                </Pressable>
              </Column>
              <Column fill>
                <Spacer />
                <Spacer />
                <Stack horizontal alignItems="center" size="slim">
                  <Text
                    variant="header"
                    weight="bold"
                    color="primary"
                    numberOfLines={1}
                    fill={
                      (organizationName ?? "").length > 20 && isSm
                        ? true
                        : undefined
                    }
                  >
                    {organizationName ?? displayName}
                  </Text>
                </Stack>
                <Spacer size="standard" />
                <Stack size="slim">
                  <Stack size="slim">
                    <StarRating
                      rating={orgRating ?? overallRating ?? 5}
                      variant="singular"
                    />
                    <Text>
                      {orgReviews?.totalCount ?? reviewsReceived?.totalCount}{" "}
                      Reviews
                    </Text>
                  </Stack>
                </Stack>
              </Column>
            </Row>
            {showWorkerBlockedOrganizationReminder(organizationId) && (
              <WorkerBlockedOrganizationReminder
                workerId={viewer?.id ?? ""}
                organizationId={organizationId}
              />
            )}
          </ContentArea>
        </Surface>
        {WorkerAttendanceWarnings.isEnabled() && (
          <OrgAttendanceCard
            fragmentRef={viewer}
            organizationId={organizationId ?? ""}
          />
        )}
        <Surface>
          <Stack variant="divider">
            <TouchableOpacity
              onPress={() =>
                navigation?.navigate("BrowseGigPrimaryContact", { id: gigId })
              }
            >
              {isShift && (
                <ListRow
                  left={
                    <Icon
                      name="user"
                      color="primary"
                      size="large"
                      variant="solid"
                    />
                  }
                  label={
                    <Text weight="bold" color="primary">
                      Primary Contact
                    </Text>
                  }
                  right={
                    <Stack
                      horizontal
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text>{displayName}</Text>
                      <Icon
                        name="chevron-right"
                        color="primary"
                        variant="solid"
                        size="small"
                      />
                    </Stack>
                  }
                />
              )}
            </TouchableOpacity>
            {industry && (
              <ListRow
                left={
                  <Icon
                    name="wrench"
                    color="primary"
                    size="large"
                    variant="solid"
                  />
                }
                label={
                  <Text weight="bold" color="primary">
                    Industry
                  </Text>
                }
                right={<Text>{industry}</Text>}
              />
            )}
            {administrativeArea1 && (
              <ListRow
                left={
                  <Icon
                    name="location-dot"
                    color="primary"
                    size="large"
                    variant="solid"
                  />
                }
                label={
                  <Text weight="bold" color="primary">
                    Location
                  </Text>
                }
                right={
                  <Text>
                    {locality &&
                      `${locality || ""}, ${administrativeArea1 ?? ""}`}
                  </Text>
                }
              />
            )}
            {companySize && (
              <ListRow
                left={
                  <Icon
                    name="user"
                    color="primary"
                    size="large"
                    variant="solid"
                  />
                }
                label={
                  <Text weight="bold" color="primary">
                    Employees
                  </Text>
                }
                right={<Text>{companySize}</Text>}
              />
            )}
            {website && (
              <ListRow
                left={
                  <Icon
                    name="globe"
                    color="primary"
                    size="large"
                    variant="solid"
                  />
                }
                label={
                  <Text weight="bold" color="primary">
                    Website
                  </Text>
                }
                right={<Text>{website}</Text>}
              />
            )}
            {yearFounded && (
              <ListRow
                left={
                  <Icon
                    name="calendar"
                    color="primary"
                    size="large"
                    variant="solid"
                  />
                }
                label={
                  <Text weight="bold" color="primary">
                    Year Founded
                  </Text>
                }
                right={<Text>{yearFounded}</Text>}
              />
            )}
            {officeType && (
              <ListRow
                left={
                  <Icon
                    name="house"
                    color="primary"
                    size="large"
                    variant="solid"
                  />
                }
                label={
                  <Text weight="bold" color="primary">
                    Office Type
                  </Text>
                }
                right={<Text>{officeType}</Text>}
              />
            )}
            {union && (
              <ListRow
                left={
                  <Icon
                    name="handshake"
                    color="primary"
                    size="large"
                    variant="solid"
                  />
                }
                label={
                  <Text weight="bold" color="primary">
                    Affiliations
                  </Text>
                }
                right={<Text>{union ? "Union" : "Non-Union"}</Text>}
              />
            )}
            {!!(requesterType === "organization" ? description : biography) && (
              <ListRow
                label={
                  <Stack size="compact">
                    <Text weight="bold" color="primary" wrap>
                      {requesterType === "organization"
                        ? "Description"
                        : "Biography"}
                    </Text>
                    <Text>
                      {requesterType === "organization"
                        ? description
                        : biography}
                    </Text>
                  </Stack>
                }
              />
            )}
          </Stack>
        </Surface>
        {hasOrgRelationship && isShift && (
          <ContentArea size="none">
            <DirectHireActionRow variant="outline" />
          </ContentArea>
        )}
        {!!showWorkerBlockOrg(response?.gig) && (
          <BlockOrganizationCollapsibleRow
            organizationId={response?.gig?.organization?.id ?? ""}
          />
        )}
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query requesterQuery(
        $id: ID!
        $gigId: ID!
        $hasGigId: Boolean!
        $query: String!
        $attendanceStartRange: DateTime
        $organizationId: ID
        $engagementsQuery: CqlFilterEngagementInput
      ) {
        viewer {
          ... on Worker {
            id
            organizations(first: 0, query: $query) {
              totalCount
            }
            ...OrgAttendanceCard_worker
              @arguments(
                startDate: $attendanceStartRange
                organizationId: $organizationId
                engagementsQuery: $engagementsQuery
              )
          }
        }
        gig: node(id: $gigId) @include(if: $hasGigId) {
          ...BlockOrganizationCollapsibleRow_shift
          ... on Gig {
            gigType
            organization {
              id
              name
              logoUrl
              industry
              locality
              postalCode
              countryCode
              locality
              administrativeArea1
              overallRating
              reviewsReceived(first: 0) {
                totalCount
              }
              street1
              street2
              companySize
              website
              union
              yearFounded
              officeType
              allowsSmoking
              description
            }
          }
          ... on GigSeries {
            gigType
            organization {
              id
              name
              logoUrl
              industry
              locality
              postalCode
              countryCode
              locality
              administrativeArea1
              overallRating
              reviewsReceived(first: 0) {
                totalCount
              }
              street1
              street2
              companySize
              website
              union
              yearFounded
              officeType
              allowsSmoking
              description
            }
          }
        }
        requester: node(id: $id) {
          ... on RequesterLike {
            id
            reviewsReceived(first: 0) {
              totalCount
            }
            profilePhoto {
              url
            }
            displayName
            overallRating
            biography
            jobTitle
            primaryOrganization {
              id
              name
              logoUrl
              industry
              locality
              postalCode
              countryCode
              locality
              administrativeArea1
              overallRating
              reviewsReceived(first: 0) {
                totalCount
              }
              street1
              street2
              companySize
              website
              union
              yearFounded
              officeType
              allowsSmoking
              description
            }
          }
        }
      }
    `,
    variables: ({ requesterId, gigId, organizationId }: Props) => ({
      id: requesterId,
      gigId: gigId ?? "",
      hasGigId: !!gigId,
      query: where({ organizationRequesterId: requesterId }).toString(),
      attendanceStartRange: now.minus({ days: 60 }).startOf("day").toString(),
      organizationId,
      engagementsQuery: {
        anyStateName: { _eq: "ENDED" },
        organizationId: { _eq: organizationId },
        actualStartsAt: {
          _gte: now.minus({ days: 60 }).startOf("day").toString()
        }
      }
    })
  }
);

function getRequesterType(gigHasOrg: boolean): "organization" | "individual" {
  return gigHasOrg ? "organization" : "individual";
}
