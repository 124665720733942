import {
  type Color,
  ContentArea,
  HighlightedReminder,
  type IconName,
  List,
  Row,
  Spacer,
  Stack,
  Text
} from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { useMemo } from "react";
import AttendanceOverviewRow from "./AttendanceOverviewRow";
import type { AttendanceOverviewDetails_worker$key } from "./__generated__/AttendanceOverviewDetails_worker.graphql";
import type { WorkerAttendanceStatus } from "./types";

interface Props {
  onPress?: (attendanceStatus: WorkerAttendanceStatus) => void;
  viewerType?: "worker" | "requester";
}

export function AttendanceOverviewDetails({
  gigStats,
  attendanceStats,
  onPress,
  viewerType = "worker"
}: FragmentContainerInnerComponentProps<
  AttendanceOverviewDetails_worker$key,
  Props
>) {
  const totalEngagementsCompleted = gigStats?.engagementsEnded ?? 0;
  const onTimeArrivals = attendanceStats?.onTime ?? 0;
  const lateArrivals = attendanceStats?.lateArrival ?? 0;
  const lateCancellations = attendanceStats?.lateCancellationTotal ?? 0;
  const lateCancellationExcused = attendanceStats?.lateCancellationExcused ?? 0;
  const lateCancellationUnexcused =
    attendanceStats?.lateCancellationUnexcused ?? 0;
  const noShows = attendanceStats?.noShowTotal ?? 0;
  const noShowsExcused = attendanceStats?.noShowExcused ?? 0;
  const noShowsUnexcused = attendanceStats?.noShowUnexcused ?? 0;
  const hasNegativeAttendanceOutcome =
    lateArrivals > 2 || lateCancellations > 2 || noShows > 2;

  const computedLateArrivalProps = useMemo(() => {
    switch (true) {
      case lateArrivals === 0 && totalEngagementsCompleted > 0:
        return {
          icon: "circle-check",
          color: "success"
        };
      case lateArrivals === 0 && totalEngagementsCompleted === 0:
        return {
          icon: "circle-check",
          color: "neutral"
        };
      case lateArrivals > 0 && lateArrivals < 3:
        return {
          icon: "circle-exclamation",
          color: "danger"
        };
      case lateArrivals >= 3:
        return {
          icon: "circle-exclamation",
          color: "error"
        };
      default:
        return {
          icon: "circle-check",
          color: "neutral"
        };
    }
  }, [lateArrivals, totalEngagementsCompleted]);

  const computedLateCancellationProps = useMemo(() => {
    switch (true) {
      case lateCancellations === 0:
        return {
          icon: "circle-check",
          color: "success"
        };
      case lateCancellations > 0 && lateCancellations < 3:
        return {
          icon: "circle-exclamation",
          color: "danger"
        };
      case lateCancellations >= 3:
        return {
          icon: "circle-exclamation",
          color: "error"
        };
      default:
        return {
          icon: "circle-check",
          color: "neutral"
        };
    }
  }, [lateCancellations]);

  const renderNestedDetails = (
    excusedAbsences: number,
    unexcusedAbsences: number
  ) => {
    return (
      <>
        <Spacer size="compact" />
        <ContentArea size="slim" variant="xlarge">
          <List variant="bullet" size="slim">
            <Row justifyContent="space-between">
              <Text>Excused Absences</Text>
              <Text>{excusedAbsences}</Text>
            </Row>
            <Row justifyContent="space-between">
              <Text>Unexcused Absences</Text>
              <Text>{unexcusedAbsences}</Text>
            </Row>
          </List>
        </ContentArea>
      </>
    );
  };

  return (
    <Stack size="medium">
      <AttendanceOverviewRow
        title="On-Time Arrivals"
        icon={onTimeArrivals > 0 ? "circle-check" : "circle-exclamation"}
        color={onTimeArrivals > 0 ? "success" : "neutral"}
        count={onTimeArrivals}
        onPress={onPress ? () => onPress?.("ON_TIME") : undefined}
      />
      <AttendanceOverviewRow
        title="Late Arrivals"
        count={lateArrivals}
        icon={computedLateArrivalProps.icon as IconName}
        color={computedLateArrivalProps.color as Color}
        onPress={onPress ? () => onPress?.("LATE_ARRIVAL") : undefined}
      />
      <AttendanceOverviewRow
        title="Late Cancellations"
        count={lateCancellations}
        icon={computedLateCancellationProps.icon as IconName}
        color={computedLateCancellationProps.color as Color}
        onPress={onPress ? () => onPress?.("LATE_CANCELLATION") : undefined}
      >
        {viewerType === "requester" &&
          lateCancellations > 0 &&
          renderNestedDetails(
            lateCancellationExcused,
            lateCancellationUnexcused
          )}
      </AttendanceOverviewRow>
      <AttendanceOverviewRow
        title="No Shows"
        count={noShows}
        icon={noShows === 0 ? "circle-check" : "circle-exclamation"}
        color={noShows === 0 ? "success" : "error"}
        onPress={onPress ? () => onPress?.("NO_SHOW") : undefined}
      >
        {viewerType === "requester" &&
          noShows > 0 &&
          renderNestedDetails(noShowsExcused, noShowsUnexcused)}
      </AttendanceOverviewRow>
      {viewerType === "worker" && (
        <HighlightedReminder
          icon="circle-exclamation"
          header="Attendance information is visible to hiring Organizations."
          variant={hasNegativeAttendanceOutcome ? "error" : "primary"}
        />
      )}
    </Stack>
  );
}

export default createRelayFragmentContainer<
  AttendanceOverviewDetails_worker$key,
  Props
>(
  graphql`
    fragment AttendanceOverviewDetails_worker on Worker
    @argumentDefinitions(
      startDate: { type: "DateTime" }
      endDate: { type: "DateTime" }
      organizationId: { type: "ID" }
    ) {
      gigStats {
        engagementsEnded
      }
      attendanceStats(end: $endDate, start: $startDate, organizationId: $organizationId) {
        onTime
        lateArrival
        lateCancellationTotal
        lateCancellationExcused
        lateCancellationUnexcused
        noShowTotal
        noShowExcused
        noShowUnexcused
      }
    }
  `,
  AttendanceOverviewDetails
);
