import InstrumentScroller, {
  type OnScrollParams
} from "@gigsmart/pickle/support/utils/instrument-scroller";
import noop from "lodash/noop";
import React, {
  Component,
  createContext,
  useContext,
  type ComponentProps,
  type ReactNode
} from "react";
import { type TextInput, findNodeHandle } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { theme } from "../style";

type ScrollToFn = (arg0: OnScrollParams) => void;
export interface ScrollToProps {
  scrollTo: ScrollToFn;
}

const Context = createContext<ScrollToFn>(noop);

export const useScrollTo = () => useContext(Context);

export type Props = ComponentProps<typeof KeyboardAwareScrollView> & {
  children?: ReactNode;
  color: string;
  testID?: string;
};

/**
 * @deprecated Try using ScreenScroll component or from Atlas
 */
export default class StyledScrollView extends Component<Props> {
  scroll: KeyboardAwareScrollView | null | undefined;

  static defaultProps = {
    color: "white",
    showsVerticalScrollIndicator: false
  };

  handleScrollTo = (params: OnScrollParams) => {
    const scroller = this.scroll;
    if (!scroller) return;

    if (params === "start") {
      scroller.scrollToPosition(0, 0, true);
    } else if (params === "end") {
      scroller.scrollToEnd();
    } else if ("y" in params || "x" in params) {
      scroller.scrollToPosition(0, 0, true);
    } else if (
      "scrollIntoView" in scroller &&
      typeof scroller.scrollIntoView === "function"
    ) {
      const node = findNodeHandle(params as typeof TextInput);
      if (node) scroller.scrollIntoView(node);
    }
  };

  render() {
    const { style, color, children, testID, ...props } = this.props;

    const containerStyles = [
      { backgroundColor: theme.color.getColor(color) },
      style
    ];

    return (
      <KeyboardAwareScrollView
        ref={(c) => (this.scroll = c)}
        style={containerStyles}
        enableResetScrollToCoords={false}
        extraScrollHeight={30}
        extraHeight={60}
        enableOnAndroid
        automaticallyAdjustContentInsets
        contentInsetAdjustmentBehavior="automatic"
        keyboardShouldPersistTaps="always"
        testID={testID}
        bounces={false}
        {...props}
      >
        <Context.Provider value={this.handleScrollTo}>
          {children}
          <InstrumentScroller
            targetId={testID}
            onScroll={this.handleScrollTo}
          />
        </Context.Provider>
      </KeyboardAwareScrollView>
    );
  }
}
