import { WorkerAttendanceWarnings } from "@gigsmart/feature-flags";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { useUserValue } from "@gigsmart/isomorphic-shared/user-value";
import {
  getConnectionEdges,
  graphql,
  useFetchQueryResult
} from "@gigsmart/relay";
import { showNoShowModal } from "@gigsmart/seibutsu/attendance/NoShowModal";
import { useEffect } from "react";
import type { useNoShowCheckQuery } from "./__generated__/useNoShowCheckQuery.graphql";

const query = graphql`
  query useNoShowCheckQuery {
    viewer {
      ... on Worker {
        engagements(
          first: 1
          where: {
            attendanceStatus: { _eq: NO_SHOW }
            actualStartsAt: { _gte: "2024-12-26T00:00:00Z" }
          }
          orderBy: { actualStartsAt: { direction: DESC } }
        ) {
          edges {
            node {
              id
              gig {
                organization {
                  name
                }
                actualStartsAt
                startsAt
                endsAt
                timezone
              }
            }
          }
        }
      }
    }
  }
`;

interface NoShowUserValue {
  // Store the ID of the last no-show engagement we've shown the modal for
  lastShownEngagementId: string | null;
}

export function useNoShowCheck() {
  const currentUser = useCurrentUser();
  const [noShowState, setNoShowState] = useUserValue<NoShowUserValue>(
    `${currentUser?.id}-no-shows`
  );

  const [{ viewer = null } = {}] =
    useFetchQueryResult<useNoShowCheckQuery>(query);
  const data = getConnectionEdges(viewer?.engagements)?.[0];

  useEffect(() => {
    if (
      WorkerAttendanceWarnings.isDisabled() ||
      !currentUser?.id ||
      !data?.node?.id
    ) {
      return;
    }

    const latestNoShow = data?.node;

    if (latestNoShow?.id !== noShowState?.lastShownEngagementId) {
      showNoShowModal({
        organizationName: latestNoShow?.gig?.organization?.name ?? "",
        actualStartsAt: latestNoShow?.gig?.actualStartsAt ?? "",
        startsAt: latestNoShow?.gig?.startsAt ?? "",
        endsAt: latestNoShow?.gig?.endsAt ?? "",
        timezone: latestNoShow?.gig?.timezone ?? "",
        onClose: () =>
          setNoShowState({
            lastShownEngagementId: latestNoShow?.id ?? null
          })
      });
    }
  }, [currentUser?.id, data?.node?.id, noShowState?.lastShownEngagementId]);
}
