import { useDebouncedCallback } from "@gigsmart/atorasu";
import React, { useState, useEffect } from "react";
import { IconInputAccessory } from "../icon";
import StyledTextInput from "../input/styled-text-input/styled-text-input";
import { type ViewStyleProp, useStyles } from "../style";

interface Props {
  fill?: number | boolean;
  onSearch: (_: string) => void;
  placeholder: string;
  containerStyle?: ViewStyleProp;
  testID?: string;
  value?: string;
}

const StyledSearchBar = ({
  fill,
  onSearch,
  placeholder,
  containerStyle,
  testID,
  value
}: Props) => {
  const { styles } = useStyles(({ color, units }) => ({
    input: { flex: 1, color: color.black },
    inputContainer: {
      backgroundColor: color.neutralLightGray,
      marginBottom: 0
    },
    icon: {
      marginLeft: units(1),
      marginRight: units(1)
    }
  }));
  const [searchTerm, setSearchTerm] = useState(value ?? "");
  const debouncedSearch = useDebouncedCallback(onSearch, 300);

  useEffect(() => {
    if (typeof value === "string" && value !== searchTerm) {
      handleTextChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleTextChange = (text: string) => {
    setSearchTerm(text);
    debouncedSearch(text);
  };

  return (
    <StyledTextInput
      fill={fill}
      maxLength={255}
      testID={testID ?? "styled-search-bar"}
      eventTargetName="Styled Search Bar"
      inputStyle={styles.input}
      containerStyle={containerStyle}
      inputContainerStyle={styles.inputContainer}
      inputContainerFocusedStyle={styles.inputContainer}
      onChangeText={handleTextChange}
      value={searchTerm}
      placeholder={placeholder}
      renderLeftAccessory={() => <IconInputAccessory name="search" />}
      clearable
    />
  );
};

export default StyledSearchBar;
