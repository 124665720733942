/**
 * @generated SignedSource<<ec7eb9ead5264125573daae8f404360e>>
 * @relayHash 2e449ae62fb797c6efbdcd3469295c86
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:PA9yaXjvZVJ3J_2JIr2-odMkW26jDgVuYLta6ICCo8E

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type WorkerShiftSummaryTabQuery$variables = {
  id: string;
};
export type WorkerShiftSummaryTabQuery$data = {
  readonly node: {
    readonly currentState?: {
      readonly name: EngagementStateName;
    };
    readonly duration?: {
      readonly totalDurationWorked: string | null | undefined;
    } | null | undefined;
    readonly gig?: {
      readonly estimatedMileage: number | null | undefined;
      readonly organization: {
        readonly id: string;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"BlockOrganizationCollapsibleRow_shift">;
    };
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"EngagementRatingStack_engagement" | "VerifyMileage_engagement" | "WithEngagementCapability_engagement" | "WorkerCancellationFeeSummary_shift" | "WorkerPaymentSurface_shift" | "WorkerTimesheetInfoCard_engagement">;
  } | null | undefined;
};
export type WorkerShiftSummaryTabQuery = {
  response: WorkerShiftSummaryTabQuery$data;
  variables: WorkerShiftSummaryTabQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedMileage",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementState",
  "kind": "LinkedField",
  "name": "currentState",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "SYSTEM"
  }
],
v6 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v8 = {
  "alias": "startedCount",
  "args": [
    (v6/*: any*/),
    {
      "kind": "Literal",
      "name": "query",
      "value": "WHERE action = START"
    }
  ],
  "concreteType": "EngagementStateOrOverridesConnection",
  "kind": "LinkedField",
  "name": "states",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": "states(first:0,query:\"WHERE action = START\")"
},
v9 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "WORKER"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOverridden",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "editable",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiresAt",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v16/*: any*/)
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalDurationWorked",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v21 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rating",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "review",
    "storageKey": null
  },
  (v16/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkerShiftSummaryTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineDataFragmentSpread",
            "name": "VerifyMileage_engagement",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Gig",
                    "kind": "LinkedField",
                    "name": "gig",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": "systemTimesheet",
                    "args": (v5/*: any*/),
                    "concreteType": "EngagementTimesheet",
                    "kind": "LinkedField",
                    "name": "timesheet",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "storageKey": "timesheet(variant:\"SYSTEM\")"
                  },
                  {
                    "alias": "workerTimesheet",
                    "args": (v9/*: any*/),
                    "concreteType": "EngagementTimesheet",
                    "kind": "LinkedField",
                    "name": "timesheet",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": "timesheet(variant:\"WORKER\")"
                  }
                ],
                "type": "Engagement",
                "abstractKey": null
              }
            ],
            "args": null,
            "argumentDefinitions": []
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkerPaymentSurface_shift"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EngagementRatingStack_engagement"
          },
          {
            "kind": "InlineDataFragmentSpread",
            "name": "WithEngagementCapability_engagement",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementCapability",
                    "kind": "LinkedField",
                    "name": "capabilities",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "restrictedBy",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v14/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Engagement",
                "abstractKey": null
              }
            ],
            "args": null,
            "argumentDefinitions": []
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkerTimesheetInfoCard_engagement"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkerCancellationFeeSummary_shift"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v16/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "BlockOrganizationCollapsibleRow_shift",
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v17/*: any*/),
                          (v18/*: any*/)
                        ],
                        "type": "GigLike",
                        "abstractKey": "__isGigLike"
                      }
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  },
                  (v2/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "duration",
                "args": null,
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Engagement",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkerShiftSummaryTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v20/*: any*/),
          (v16/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reconcilesAt",
                    "storageKey": null
                  },
                  (v18/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isGigLike"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "transitionedAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "systemTimesheet",
                "args": (v5/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": "workerApprovals",
                    "args": [
                      (v6/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "query",
                        "value": "WHERE approvedByType = WORKER"
                      }
                    ],
                    "concreteType": "EngagementTimesheetApprovalsConnection",
                    "kind": "LinkedField",
                    "name": "approvals",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": "approvals(first:0,query:\"WHERE approvedByType = WORKER\")"
                  }
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              },
              {
                "alias": "workerTimesheet",
                "args": (v9/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"WORKER\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementPaymentInfo",
                "kind": "LinkedField",
                "name": "paymentInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "billableDuration",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "additionalPay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerServiceFee",
                    "kind": "LinkedField",
                    "name": "serviceFees",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hourlyRate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "feeType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "minimumAmount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "percentageRate",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "basePay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "netPay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "payRate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "grossPay",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "finalTimesheet",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "variant",
                    "value": "FINAL"
                  }
                ],
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paymentStyle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "submittedBy",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"FINAL\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementCapability",
                "kind": "LinkedField",
                "name": "capabilities",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "restrictedBy",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementReview",
                "kind": "LinkedField",
                "name": "reviewOfWorker",
                "plural": false,
                "selections": (v21/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementReview",
                "kind": "LinkedField",
                "name": "reviewOfRequester",
                "plural": false,
                "selections": (v21/*: any*/),
                "storageKey": null
              },
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementCancellationFee",
                "kind": "LinkedField",
                "name": "cancellationFee",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "feeRate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startDelta",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scheduledAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scheduledDuration",
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "duration",
                "args": null,
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v19/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Engagement",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:PA9yaXjvZVJ3J_2JIr2-odMkW26jDgVuYLta6ICCo8E",
    "metadata": {},
    "name": "WorkerShiftSummaryTabQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "d68b30050fa655e5a20ad75f6f4c9e63";

export default node;
