import { Avatar, Sticker } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { type ComponentProps } from "react";

import type { WorkerAvatar_worker$key } from "./__generated__/WorkerAvatar_worker.graphql";

interface Props {
  disabled?: boolean;
  size?: ComponentProps<typeof Avatar>["size"];
  showWorkerGroups?: boolean;
  showSticker?: boolean;
  onPress?: () => void;
}

export const WorkerAvatar = ({
  size,
  disabled,
  profilePhoto,
  isBlocked,
  isFavorite,
  belongsToWorkerGroup,
  showSticker = true,
  showWorkerGroups,
  onPress
}: FragmentContainerInnerComponentProps<WorkerAvatar_worker$key, Props>) => (
  <Avatar
    size={size}
    uri={profilePhoto?.url}
    icon="user"
    disabled={disabled}
    onPress={onPress}
    sticker={
      !showSticker ? undefined : isBlocked ? (
        <Sticker icon="octagon-xmark" color="textPrimary" />
      ) : isFavorite ? (
        <Sticker icon="star" color="purple" />
      ) : showWorkerGroups && belongsToWorkerGroup ? (
        <Sticker icon="users-class" color="purple" />
      ) : undefined
    }
  />
);

export default createRelayFragmentContainer<WorkerAvatar_worker$key, Props>(
  graphql`
    fragment WorkerAvatar_worker on Worker {
      isBlocked
      isFavorite
      belongsToWorkerGroup
      profilePhoto {
        url
      }
    }
  `,
  WorkerAvatar
);
