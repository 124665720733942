/**
 * @generated SignedSource<<2465da034aeda03684726ee539274a44>>
 * @relayHash 1011fea5370f5389feb63796053d6bd1
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:yYA3IV1caIt-kgpsCt_2_lWWUxKvjPEjDbCSW45wIec

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type attendanceOverviewScreenQuery$variables = {
  endDate?: string | null | undefined;
  startDate?: string | null | undefined;
};
export type attendanceOverviewScreenQuery$data = {
  readonly viewer: {
    readonly gigStats?: {
      readonly engagementsScheduled: number;
    } | null | undefined;
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"AttendanceOverviewDetails_worker">;
  } | null | undefined;
};
export type attendanceOverviewScreenQuery = {
  response: attendanceOverviewScreenQuery$data;
  variables: attendanceOverviewScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "engagementsScheduled",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "attendanceOverviewScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerGigStats",
                "kind": "LinkedField",
                "name": "gigStats",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "endDate",
                    "variableName": "endDate"
                  },
                  {
                    "kind": "Variable",
                    "name": "startDate",
                    "variableName": "startDate"
                  }
                ],
                "kind": "FragmentSpread",
                "name": "AttendanceOverviewDetails_worker"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "attendanceOverviewScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerGigStats",
                "kind": "LinkedField",
                "name": "gigStats",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "engagementsEnded",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "end",
                    "variableName": "endDate"
                  },
                  {
                    "kind": "Variable",
                    "name": "start",
                    "variableName": "startDate"
                  }
                ],
                "concreteType": "WorkerAttendanceStats",
                "kind": "LinkedField",
                "name": "attendanceStats",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "onTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lateArrival",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lateCancellationTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lateCancellationExcused",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lateCancellationUnexcused",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "noShowTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "noShowExcused",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "noShowUnexcused",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:yYA3IV1caIt-kgpsCt_2_lWWUxKvjPEjDbCSW45wIec",
    "metadata": {},
    "name": "attendanceOverviewScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "72bbf6bece876bbc4a8dc7427027feea";

export default node;
