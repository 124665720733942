import { Icon, Platform, Text } from "@gigsmart/atorasu";
import React, { type ReactNode } from "react";

import { MaskedTextInput } from "@gigsmart/atorasu/molecules";
import { type FormFieldOptions, Validator, useFormField } from "@gigsmart/fomu";

export interface Props extends FormFieldOptions<string> {
  horizontal?: boolean;
  variant?: "fixed" | "hourly";
  min?: number;
  max?: number;
  label?: string | ReactNode;
  note?: string;
  mask?: "money" | "money:3" | "money:4";
  placeholder?: string;
  errorLabel?: string;
  onChange?: (value: string) => void;
}

export default function FomuMoneyInput({
  horizontal,
  variant = "fixed",
  mask = "money",
  label,
  name,
  validates,
  sensitive,
  shouldShowErrors,
  errorLabel,
  placeholder,
  min,
  max,
  note,
  onChange,
  initialValue
}: Props) {
  const { setValue, value, submit, errors, showErrors, triggerFocus } =
    useFormField<string>({
      name,
      validates: [
        ...(max
          ? [
              Validator.maxNumber({
                max,
                message:
                  errorLabel ?? label
                    ? `${errorLabel ?? label} cannot exceed $${max}`
                    : `Cannot exceed $${max}`
              })
            ]
          : []),
        ...(min
          ? [
              Validator.minNumber({
                min,
                message:
                  errorLabel ?? label
                    ? `${errorLabel ?? label} cannot be less than $${min}`
                    : `Cannot be less than $${min}`
              })
            ]
          : []),
        ...(Array.isArray(validates) ? validates : validates ? [validates] : [])
      ],
      sensitive,
      shouldShowErrors,
      initialValue
    });
  return (
    <MaskedTextInput
      horizontal={horizontal}
      label={label}
      type="number"
      mask={mask}
      testID={`${name}-money-input`}
      placeholder={placeholder ?? "0"}
      editable
      onSubmitEditing={Platform.OS === "web" ? submit : undefined}
      value={value ?? undefined}
      onChangeText={(value) => {
        setValue(value);
        onChange?.(value);
      }}
      onBlur={showErrors}
      onFocus={triggerFocus}
      errors={errors}
      leftAccessory={
        <Icon size="small" color="primary" variant="solid" name="dollar-sign" />
      }
      note={note}
      rightAccessory={variant === "hourly" ? <Text>/hr</Text> : null}
    />
  );
}
