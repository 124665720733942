/**
 * @generated SignedSource<<d6a6da168615404222003794626102e5>>
 * @relayHash be1deaa82d93ccd8f43238ab46e13058
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:QCbtf4rYrzO7GfrmiDxqunAj1BOwX4_fEjA0JyfCMR0

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CancellationReasonExcuseDisposition = "EXCUSED" | "UNEXCUSED" | "%future added value";
export type CancellationReasonType = "CANCELED" | "DID_NOT_WORK" | "ISSUE_WITH_WORKER" | "NO_LONGER_AVAILABLE" | "RECEIVED_ANOTHER_OFFER" | "REPORT_NO_SHOW" | "REQUESTER_UNRESPONSIVE" | "UNABLE_TO_FINALIZE" | "UNSPECIFIED" | "%future added value";
export type CqlSortDirection = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type DistanceUnit = "KILOMETERS" | "METERS" | "MILES" | "%future added value";
export type EngagementCommitmentResponse = "ACCEPT" | "DECLINE" | "PENDING" | "TIMEOUT" | "%future added value";
export type EngagementDisputeDisposition = "APPROVED" | "REJECTED" | "WITHDRAWN" | "%future added value";
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type EngagementWorkerLocationStatus = "INSIDE_GEOFENCE" | "NOT_TRACKED" | "OUTSIDE_GEOFENCE" | "%future added value";
export type GigAudienceType = "FAVORITES" | "PREVIOUSLY_WORKED" | "PUBLIC" | "WORKER_GROUP" | "%future added value";
export type GigStateName = "ACTIVE" | "CANCELED" | "COMPLETED" | "DRAFT" | "EXPIRED" | "INACTIVE" | "INCOMPLETE" | "IN_PROGRESS" | "PENDING_REVIEW" | "RECONCILED" | "UPCOMING" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type ScheduledEventAction = "APPLICATION_TIMEOUT" | "ARCHIVE_TOPIC" | "AUTO_OFFER_WORKERS" | "BATCH_CONFIRM" | "CANCEL_GIG" | "CANCEL_OUTSTANDING_OFFERS" | "CHECK_COMMITMENT" | "CHECK_CONFIRMATION" | "CHECK_PREAUTH" | "CLEAN" | "CREATE_COMMITMENT" | "ELAPSED_START" | "END_GIG" | "EXCEED_LATEST_ARRIVAL_TIME" | "EXPIRE" | "EXPIRING_SOON" | "FIFTEEN_MINUTES_BEFORE_GIG_END_WARNING" | "FIVE_MINUTES_BEFORE_WARNING" | "GIG_EXPIRATION" | "GIG_HAS_ENDED_WARNING" | "GIG_PICKUP_WINDOW_AVAILABLE" | "JOB_EXPIRING" | "MISS_GIG" | "NEARING_GIG_END_WARNING" | "ONE_DAY_BEFORE_GIG_WARNING" | "ONE_DAY_BEFORE_WARNING" | "OUTSTANDING_DISPUTE_WARNING" | "PREAUTHORIZE" | "RECALCULATE_STATE" | "RECONCILE" | "RECONCILE_TIMESHEET" | "REJECT_DISPUTE" | "REQUESTER_WORKER_INACTIVE_WARNING" | "SIXTY_MINUTES_BEFORE_GIG_WARNING" | "SIXTY_MINUTES_BEFORE_WARNING" | "THIRTY_MINUTES_BEFORE_GIG_WARNING" | "THIRTY_MINUTES_BEFORE_WARNING" | "THREE_HOURS_BEFORE_GIG_WARNING" | "THREE_HOURS_BEFORE_WARNING" | "TIMEOUT" | "TIMEOUT_APPLICANTS" | "TIMEOUT_CONFIRMATION" | "WORKER_APPROVE_TIMESHEET_EXPIRATION" | "WORKER_ARRIVING_WARNING" | "WORKER_AWAITING_START_WARNING" | "WORKER_INACTIVE_WARNING" | "WORKER_NOT_VERIFIED" | "WORKER_VERIFIED" | "%future added value";
export type ScheduledEventType = "BATCH_ENGAGEMENT_EVENT" | "DIRECT_HIRE_EVENT" | "GIG_LOTTERY_TIMED_EVENT" | "GIG_PREAUTHORIZATION_EVENT" | "GIG_SERIES_CONFIRMATION_CHECK" | "GIG_SERIES_CONFIRMATION_TIMEOUT" | "GIG_SERIES_EVENT" | "GIG_SERIES_TIMED_EVENT" | "GIG_STATE_EVENT" | "GIG_TIMED_ALERT" | "GIG_TIMED_EVENT" | "GIG_WORKER_ALERT" | "GIG_WORKER_EVENT" | "JOB_POSTING_EVENT" | "%future added value";
export type UserType = "ADMIN" | "ORGANIZATION_APPLICATION" | "ORGANIZATION_REQUESTER" | "REQUESTER" | "SYSTEM" | "WORKER" | "%future added value";
export type WorkerAttendanceStatus = "LATE_ARRIVAL" | "LATE_CANCELLATION" | "NO_SHOW" | "ON_TIME" | "%future added value";
export type WorkerTransportationState = "MOTORCYCLE" | "SEDAN" | "SUV" | "TRUCK" | "VAN" | "%future added value";
export type CqlOrderEngagementInput = {
  actualStartsAt?: CqlOrderStandardInput | null | undefined;
  assignedAdminId?: CqlOrderStandardInput | null | undefined;
  attendanceStatus?: CqlOrderPriorityWorkerAttendanceStatusInput | null | undefined;
  audienceMembership?: CqlOrderPriorityGigAudienceTypeInput | null | undefined;
  averageWorkerRating?: CqlOrderStandardInput | null | undefined;
  calculatedStart?: CqlOrderStandardInput | null | undefined;
  cancellationReasonType?: CqlOrderPriorityCancellationReasonTypeInput | null | undefined;
  commitmentResponse?: CqlOrderPriorityEngagementCommitmentResponseInput | null | undefined;
  completedGigCount?: CqlOrderStandardInput | null | undefined;
  currentPredictionEnded?: CqlOrderStandardInput | null | undefined;
  currentStateAction?: CqlOrderPriorityEngagementStateActionInput | null | undefined;
  currentStateName?: CqlOrderPriorityEngagementStateNameInput | null | undefined;
  currentStateOperationKey?: CqlOrderStandardInput | null | undefined;
  currentStateTransitionedAt?: CqlOrderStandardInput | null | undefined;
  currentStateTransitionedBy?: CqlOrderStandardInput | null | undefined;
  currentStateTransitionedByType?: CqlOrderPriorityUserTypeInput | null | undefined;
  endsAt?: CqlOrderStandardInput | null | undefined;
  estimatedAmount?: CqlOrderStandardInput | null | undefined;
  excusable?: CqlOrderStandardInput | null | undefined;
  excusableUntil?: CqlOrderStandardInput | null | undefined;
  excuseDisposition?: CqlOrderPriorityCancellationReasonExcuseDispositionInput | null | undefined;
  gigAllSlotsFilled?: CqlOrderStandardInput | null | undefined;
  gigEndsAt?: CqlOrderStandardInput | null | undefined;
  gigId?: CqlOrderStandardInput | null | undefined;
  gigName?: CqlOrderStandardInput | null | undefined;
  gigSlots?: CqlOrderStandardInput | null | undefined;
  gigStateName?: CqlOrderPriorityGigStateNameInput | null | undefined;
  gigType?: CqlOrderPriorityGigTypeInput | null | undefined;
  hasDisputes?: CqlOrderStandardInput | null | undefined;
  hasPayout?: CqlOrderStandardInput | null | undefined;
  hasRequesterReview?: CqlOrderStandardInput | null | undefined;
  id?: CqlOrderStandardInput | null | undefined;
  insertedAt?: CqlOrderStandardInput | null | undefined;
  isAsap?: CqlOrderStandardInput | null | undefined;
  lastKnownWorkerLocation?: CqlOrderGeoInput | null | undefined;
  locationStatus?: CqlOrderPriorityEngagementWorkerLocationStatusInput | null | undefined;
  organizationId?: CqlOrderStandardInput | null | undefined;
  organizationLocationId?: CqlOrderStandardInput | null | undefined;
  organizationName?: CqlOrderStandardInput | null | undefined;
  organizationPositionId?: CqlOrderStandardInput | null | undefined;
  organizationPositionName?: CqlOrderStandardInput | null | undefined;
  paidByRequester?: CqlOrderStandardInput | null | undefined;
  payRate?: CqlOrderStandardInput | null | undefined;
  publishedAt?: CqlOrderStandardInput | null | undefined;
  ratingOfRequester?: CqlOrderStandardInput | null | undefined;
  ratingOfWorker?: CqlOrderStandardInput | null | undefined;
  reportingType?: CqlOrderStandardInput | null | undefined;
  requesterId?: CqlOrderStandardInput | null | undefined;
  requesterName?: CqlOrderStandardInput | null | undefined;
  startsAt?: CqlOrderStandardInput | null | undefined;
  workerBelongsToGroup?: CqlOrderStandardInput | null | undefined;
  workerCanApproveTimesheet?: CqlOrderStandardInput | null | undefined;
  workerDistance?: CqlOrderStandardInput | null | undefined;
  workerEmail?: CqlOrderStandardInput | null | undefined;
  workerEstimatedLate?: CqlOrderStandardInput | null | undefined;
  workerFullName?: CqlOrderStandardInput | null | undefined;
  workerGroupId?: CqlOrderStandardInput | null | undefined;
  workerId?: CqlOrderStandardInput | null | undefined;
  workerIsFavorite?: CqlOrderStandardInput | null | undefined;
  workerLocationReportedAt?: CqlOrderStandardInput | null | undefined;
  workerMobileNumber?: CqlOrderStandardInput | null | undefined;
  workerName?: CqlOrderStandardInput | null | undefined;
  workerOver18?: CqlOrderStandardInput | null | undefined;
  workerTransportationState?: CqlOrderPriorityWorkerTransportationStateInput | null | undefined;
};
export type CqlOrderStandardInput = {
  direction: CqlSortDirection;
};
export type CqlOrderPriorityWorkerAttendanceStatusInput = {
  direction?: CqlSortDirection | null | undefined;
  priority?: ReadonlyArray<WorkerAttendanceStatus> | null | undefined;
};
export type CqlOrderPriorityGigAudienceTypeInput = {
  direction?: CqlSortDirection | null | undefined;
  priority?: ReadonlyArray<GigAudienceType> | null | undefined;
};
export type CqlOrderPriorityCancellationReasonTypeInput = {
  direction?: CqlSortDirection | null | undefined;
  priority?: ReadonlyArray<CancellationReasonType> | null | undefined;
};
export type CqlOrderPriorityEngagementCommitmentResponseInput = {
  direction?: CqlSortDirection | null | undefined;
  priority?: ReadonlyArray<EngagementCommitmentResponse> | null | undefined;
};
export type CqlOrderPriorityEngagementStateActionInput = {
  direction?: CqlSortDirection | null | undefined;
  priority?: ReadonlyArray<EngagementStateAction> | null | undefined;
};
export type CqlOrderPriorityEngagementStateNameInput = {
  direction?: CqlSortDirection | null | undefined;
  priority?: ReadonlyArray<EngagementStateName> | null | undefined;
};
export type CqlOrderPriorityUserTypeInput = {
  direction?: CqlSortDirection | null | undefined;
  priority?: ReadonlyArray<UserType> | null | undefined;
};
export type CqlOrderPriorityCancellationReasonExcuseDispositionInput = {
  direction?: CqlSortDirection | null | undefined;
  priority?: ReadonlyArray<CancellationReasonExcuseDisposition> | null | undefined;
};
export type CqlOrderPriorityGigStateNameInput = {
  direction?: CqlSortDirection | null | undefined;
  priority?: ReadonlyArray<GigStateName> | null | undefined;
};
export type CqlOrderPriorityGigTypeInput = {
  direction?: CqlSortDirection | null | undefined;
  priority?: ReadonlyArray<GigType> | null | undefined;
};
export type CqlOrderGeoInput = {
  center?: Coordinates | null | undefined;
  direction: CqlSortDirection;
};
export type Coordinates = {
  latitude: number;
  longitude: number;
};
export type CqlOrderPriorityEngagementWorkerLocationStatusInput = {
  direction?: CqlSortDirection | null | undefined;
  priority?: ReadonlyArray<EngagementWorkerLocationStatus> | null | undefined;
};
export type CqlOrderPriorityWorkerTransportationStateInput = {
  direction?: CqlSortDirection | null | undefined;
  priority?: ReadonlyArray<WorkerTransportationState> | null | undefined;
};
export type CqlFilterEngagementInput = {
  _and?: ReadonlyArray<CqlFilterEngagementInput> | null | undefined;
  _not?: CqlFilterEngagementInput | null | undefined;
  _or?: ReadonlyArray<CqlFilterEngagementInput> | null | undefined;
  actualStartsAt?: CqlOpDatetimeInput | null | undefined;
  anyEngagementDisputeDisposition?: CqlEnumEngagementDisputeDispositionInput | null | undefined;
  anyScheduledEventAction?: CqlEnumScheduledEventActionInput | null | undefined;
  anyScheduledEventType?: CqlEnumScheduledEventTypeInput | null | undefined;
  anyStateAction?: CqlEnumEngagementStateActionInput | null | undefined;
  anyStateName?: CqlEnumEngagementStateNameInput | null | undefined;
  anyStateOperationKey?: CqlOpStringInput | null | undefined;
  assignedAdminId?: CqlOpIdInput | null | undefined;
  attendanceStatus?: CqlEnumWorkerAttendanceStatusInput | null | undefined;
  audienceMembership?: CqlEnumGigAudienceTypeInput | null | undefined;
  averageWorkerRating?: CqlOpFloatInput | null | undefined;
  calculatedStart?: CqlOpDatetimeInput | null | undefined;
  cancellationReasonType?: CqlEnumCancellationReasonTypeInput | null | undefined;
  commitmentResponse?: CqlEnumEngagementCommitmentResponseInput | null | undefined;
  completedGigCount?: CqlOpIntegerInput | null | undefined;
  currentPredictionEnded?: CqlOpFloatInput | null | undefined;
  currentStateAction?: CqlEnumEngagementStateActionInput | null | undefined;
  currentStateName?: CqlEnumEngagementStateNameInput | null | undefined;
  currentStateOperationKey?: CqlOpStringInput | null | undefined;
  currentStateTransitionedAt?: CqlOpDatetimeInput | null | undefined;
  currentStateTransitionedBy?: CqlOpIdInput | null | undefined;
  currentStateTransitionedByType?: CqlEnumUserTypeInput | null | undefined;
  endsAt?: CqlOpDatetimeInput | null | undefined;
  estimatedAmount?: CqlOpFloatInput | null | undefined;
  excusable?: CqlOpBooleanInput | null | undefined;
  excusableUntil?: CqlOpDatetimeInput | null | undefined;
  excuseDisposition?: CqlEnumCancellationReasonExcuseDispositionInput | null | undefined;
  gigAllSlotsFilled?: CqlOpBooleanInput | null | undefined;
  gigEndsAt?: CqlOpDatetimeInput | null | undefined;
  gigId?: CqlOpIdInput | null | undefined;
  gigName?: CqlOpStringInput | null | undefined;
  gigSlots?: CqlOpIntegerInput | null | undefined;
  gigStateName?: CqlEnumGigStateNameInput | null | undefined;
  gigType?: CqlEnumGigTypeInput | null | undefined;
  hasDisputes?: CqlOpBooleanInput | null | undefined;
  hasPayout?: CqlOpBooleanInput | null | undefined;
  hasRequesterReview?: CqlOpBooleanInput | null | undefined;
  id?: CqlOpIdInput | null | undefined;
  insertedAt?: CqlOpDatetimeInput | null | undefined;
  isAsap?: CqlOpBooleanInput | null | undefined;
  lastKnownWorkerLocation?: CqlOpCoordinatesInput | null | undefined;
  locationStatus?: CqlEnumEngagementWorkerLocationStatusInput | null | undefined;
  organizationId?: CqlOpIdInput | null | undefined;
  organizationLocationId?: CqlOpIdInput | null | undefined;
  organizationName?: CqlOpStringInput | null | undefined;
  organizationPositionId?: CqlOpIdInput | null | undefined;
  organizationPositionName?: CqlOpStringInput | null | undefined;
  paidByRequester?: CqlOpBooleanInput | null | undefined;
  payRate?: CqlOpFloatInput | null | undefined;
  publishedAt?: CqlOpDatetimeInput | null | undefined;
  ratingOfRequester?: CqlOpIntegerInput | null | undefined;
  ratingOfWorker?: CqlOpIntegerInput | null | undefined;
  reportingType?: CqlOpDatetimeInput | null | undefined;
  requesterId?: CqlOpIdInput | null | undefined;
  requesterName?: CqlOpStringInput | null | undefined;
  startsAt?: CqlOpDatetimeInput | null | undefined;
  workerBelongsToGroup?: CqlOpBooleanInput | null | undefined;
  workerCanApproveTimesheet?: CqlOpBooleanInput | null | undefined;
  workerDistance?: CqlOpFloatInput | null | undefined;
  workerEmail?: CqlOpStringInput | null | undefined;
  workerEstimatedLate?: CqlOpBooleanInput | null | undefined;
  workerFullName?: CqlOpStringInput | null | undefined;
  workerGroupId?: CqlOpIdInput | null | undefined;
  workerId?: CqlOpIdInput | null | undefined;
  workerIsFavorite?: CqlOpBooleanInput | null | undefined;
  workerLocationReportedAt?: CqlOpDatetimeInput | null | undefined;
  workerMobileNumber?: CqlOpStringInput | null | undefined;
  workerName?: CqlOpStringInput | null | undefined;
  workerOver18?: CqlOpBooleanInput | null | undefined;
  workerTransportationState?: CqlEnumWorkerTransportationStateInput | null | undefined;
};
export type CqlOpDatetimeInput = {
  _eq?: string | null | undefined;
  _gt?: string | null | undefined;
  _gte?: string | null | undefined;
  _isNull?: boolean | null | undefined;
  _lt?: string | null | undefined;
  _lte?: string | null | undefined;
  _neq?: string | null | undefined;
};
export type CqlEnumEngagementDisputeDispositionInput = {
  _eq?: EngagementDisputeDisposition | null | undefined;
  _in?: ReadonlyArray<EngagementDisputeDisposition> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: EngagementDisputeDisposition | null | undefined;
};
export type CqlEnumScheduledEventActionInput = {
  _eq?: ScheduledEventAction | null | undefined;
  _in?: ReadonlyArray<ScheduledEventAction> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: ScheduledEventAction | null | undefined;
};
export type CqlEnumScheduledEventTypeInput = {
  _eq?: ScheduledEventType | null | undefined;
  _in?: ReadonlyArray<ScheduledEventType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: ScheduledEventType | null | undefined;
};
export type CqlEnumEngagementStateActionInput = {
  _eq?: EngagementStateAction | null | undefined;
  _in?: ReadonlyArray<EngagementStateAction> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: EngagementStateAction | null | undefined;
};
export type CqlEnumEngagementStateNameInput = {
  _eq?: EngagementStateName | null | undefined;
  _in?: ReadonlyArray<EngagementStateName> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: EngagementStateName | null | undefined;
};
export type CqlOpStringInput = {
  _eq?: string | null | undefined;
  _ilike?: string | null | undefined;
  _in?: ReadonlyArray<string> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: string | null | undefined;
};
export type CqlOpIdInput = {
  _eq?: string | null | undefined;
  _in?: ReadonlyArray<string> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: string | null | undefined;
};
export type CqlEnumWorkerAttendanceStatusInput = {
  _eq?: WorkerAttendanceStatus | null | undefined;
  _in?: ReadonlyArray<WorkerAttendanceStatus> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: WorkerAttendanceStatus | null | undefined;
};
export type CqlEnumGigAudienceTypeInput = {
  _eq?: GigAudienceType | null | undefined;
  _in?: ReadonlyArray<GigAudienceType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: GigAudienceType | null | undefined;
};
export type CqlOpFloatInput = {
  _eq?: number | null | undefined;
  _gt?: number | null | undefined;
  _gte?: number | null | undefined;
  _isNull?: boolean | null | undefined;
  _lt?: number | null | undefined;
  _lte?: number | null | undefined;
  _neq?: number | null | undefined;
};
export type CqlEnumCancellationReasonTypeInput = {
  _eq?: CancellationReasonType | null | undefined;
  _in?: ReadonlyArray<CancellationReasonType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: CancellationReasonType | null | undefined;
};
export type CqlEnumEngagementCommitmentResponseInput = {
  _eq?: EngagementCommitmentResponse | null | undefined;
  _in?: ReadonlyArray<EngagementCommitmentResponse> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: EngagementCommitmentResponse | null | undefined;
};
export type CqlOpIntegerInput = {
  _eq?: number | null | undefined;
  _gt?: number | null | undefined;
  _gte?: number | null | undefined;
  _in?: ReadonlyArray<number> | null | undefined;
  _isNull?: boolean | null | undefined;
  _lt?: number | null | undefined;
  _lte?: number | null | undefined;
  _neq?: number | null | undefined;
};
export type CqlEnumUserTypeInput = {
  _eq?: UserType | null | undefined;
  _in?: ReadonlyArray<UserType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: UserType | null | undefined;
};
export type CqlOpBooleanInput = {
  _eq?: boolean | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: boolean | null | undefined;
};
export type CqlEnumCancellationReasonExcuseDispositionInput = {
  _eq?: CancellationReasonExcuseDisposition | null | undefined;
  _in?: ReadonlyArray<CancellationReasonExcuseDisposition> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: CancellationReasonExcuseDisposition | null | undefined;
};
export type CqlEnumGigStateNameInput = {
  _eq?: GigStateName | null | undefined;
  _in?: ReadonlyArray<GigStateName> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: GigStateName | null | undefined;
};
export type CqlEnumGigTypeInput = {
  _eq?: GigType | null | undefined;
  _in?: ReadonlyArray<GigType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: GigType | null | undefined;
};
export type CqlOpCoordinatesInput = {
  _eq?: Coordinates | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: Coordinates | null | undefined;
  _stDwithin?: CqlOpDwithinInput | null | undefined;
  _stWithinBoundingBox?: BoundingBoxCoordinates | null | undefined;
};
export type CqlOpDwithinInput = {
  coordinates: Coordinates;
  distanceUnit?: DistanceUnit | null | undefined;
  radius: number;
};
export type BoundingBoxCoordinates = {
  northeastCoordinates: Coordinates;
  southwestCoordinates: Coordinates;
};
export type CqlEnumEngagementWorkerLocationStatusInput = {
  _eq?: EngagementWorkerLocationStatus | null | undefined;
  _in?: ReadonlyArray<EngagementWorkerLocationStatus> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: EngagementWorkerLocationStatus | null | undefined;
};
export type CqlEnumWorkerTransportationStateInput = {
  _eq?: WorkerTransportationState | null | undefined;
  _in?: ReadonlyArray<WorkerTransportationState> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: WorkerTransportationState | null | undefined;
};
export type ProjectGigsTabPageQuery$variables = {
  after?: string | null | undefined;
  count?: number | null | undefined;
  id: string;
  orderBy?: ReadonlyArray<CqlOrderEngagementInput> | null | undefined;
  where: CqlFilterEngagementInput;
};
export type ProjectGigsTabPageQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ProjectGigsTabPage_worker">;
  } | null | undefined;
};
export type ProjectGigsTabPageQuery = {
  response: ProjectGigsTabPageQuery$data;
  variables: ProjectGigsTabPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": 10,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v6 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v7 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v8 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "where"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  (v6/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v7/*: any*/),
  (v8/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "insertedAt",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 0
  },
  {
    "kind": "Literal",
    "name": "query",
    "value": "WHERE receiptViewedAt = NULL AND messageType = USER_MESSAGE"
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectGigsTabPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              (v6/*: any*/),
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "ProjectGigsTabPage_worker"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectGigsTabPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v11/*: any*/),
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "workerDistance",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementState",
                            "kind": "LinkedField",
                            "name": "currentState",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserTopic",
                            "kind": "LinkedField",
                            "name": "topic",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": (v15/*: any*/),
                                "concreteType": "MessagesConnection",
                                "kind": "LinkedField",
                                "name": "messages",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "totalCount",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "MessagesEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      (v16/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Message",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v9/*: any*/),
                                          (v10/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v17/*: any*/)
                                ],
                                "storageKey": "messages(first:0,query:\"WHERE receiptViewedAt = NULL AND messageType = USER_MESSAGE\")"
                              },
                              {
                                "alias": null,
                                "args": (v15/*: any*/),
                                "filters": [
                                  "query"
                                ],
                                "handle": "connection",
                                "key": "EngagedProjectHeader_messages",
                                "kind": "LinkedHandle",
                                "name": "messages"
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              (v13/*: any*/),
                              (v12/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endsAt",
                                "storageKey": null
                              },
                              (v14/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Place",
                                "kind": "LinkedField",
                                "name": "place",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "streetAddress",
                                    "storageKey": null
                                  },
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "placeId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "area",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "gigType",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "negotiable",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "paymentType",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigCategory",
                                "kind": "LinkedField",
                                "name": "category",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "displayName",
                                    "storageKey": null
                                  },
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v11/*: any*/),
                "filters": [
                  "where",
                  "orderBy"
                ],
                "handle": "connection",
                "key": "ProjectGigsTab_engagements",
                "kind": "LinkedHandle",
                "name": "engagements"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:QCbtf4rYrzO7GfrmiDxqunAj1BOwX4_fEjA0JyfCMR0",
    "metadata": {},
    "name": "ProjectGigsTabPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "d8791d7d134f8bac19d17b7ed88845b9";

export default node;
