/**
 * @generated SignedSource<<2d7b6ac257273e0f65bf6a8ce0efe1d7>>
 * @relayHash 8a624f7175d7cb67bd4b439f93ed17eb
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:GMlUo6AnVBW0qRJMoT3aUDvcAy7ETxYtmZZ6GXV5EN0

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CancellationReasonExcuseDisposition = "EXCUSED" | "UNEXCUSED" | "%future added value";
export type CancellationReasonType = "CANCELED" | "DID_NOT_WORK" | "ISSUE_WITH_WORKER" | "NO_LONGER_AVAILABLE" | "RECEIVED_ANOTHER_OFFER" | "REPORT_NO_SHOW" | "REQUESTER_UNRESPONSIVE" | "UNABLE_TO_FINALIZE" | "UNSPECIFIED" | "%future added value";
export type DistanceUnit = "KILOMETERS" | "METERS" | "MILES" | "%future added value";
export type EngagementCommitmentResponse = "ACCEPT" | "DECLINE" | "PENDING" | "TIMEOUT" | "%future added value";
export type EngagementDisputeDisposition = "APPROVED" | "REJECTED" | "WITHDRAWN" | "%future added value";
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type EngagementWorkerLocationStatus = "INSIDE_GEOFENCE" | "NOT_TRACKED" | "OUTSIDE_GEOFENCE" | "%future added value";
export type GigAudienceType = "FAVORITES" | "PREVIOUSLY_WORKED" | "PUBLIC" | "WORKER_GROUP" | "%future added value";
export type GigStateName = "ACTIVE" | "CANCELED" | "COMPLETED" | "DRAFT" | "EXPIRED" | "INACTIVE" | "INCOMPLETE" | "IN_PROGRESS" | "PENDING_REVIEW" | "RECONCILED" | "UPCOMING" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type ScheduledEventAction = "APPLICATION_TIMEOUT" | "ARCHIVE_TOPIC" | "AUTO_OFFER_WORKERS" | "BATCH_CONFIRM" | "CANCEL_GIG" | "CANCEL_OUTSTANDING_OFFERS" | "CHECK_COMMITMENT" | "CHECK_CONFIRMATION" | "CHECK_PREAUTH" | "CLEAN" | "CREATE_COMMITMENT" | "ELAPSED_START" | "END_GIG" | "EXCEED_LATEST_ARRIVAL_TIME" | "EXPIRE" | "EXPIRING_SOON" | "FIFTEEN_MINUTES_BEFORE_GIG_END_WARNING" | "FIVE_MINUTES_BEFORE_WARNING" | "GIG_EXPIRATION" | "GIG_HAS_ENDED_WARNING" | "GIG_PICKUP_WINDOW_AVAILABLE" | "JOB_EXPIRING" | "MISS_GIG" | "NEARING_GIG_END_WARNING" | "ONE_DAY_BEFORE_GIG_WARNING" | "ONE_DAY_BEFORE_WARNING" | "OUTSTANDING_DISPUTE_WARNING" | "PREAUTHORIZE" | "RECALCULATE_STATE" | "RECONCILE" | "RECONCILE_TIMESHEET" | "REJECT_DISPUTE" | "REQUESTER_WORKER_INACTIVE_WARNING" | "SIXTY_MINUTES_BEFORE_GIG_WARNING" | "SIXTY_MINUTES_BEFORE_WARNING" | "THIRTY_MINUTES_BEFORE_GIG_WARNING" | "THIRTY_MINUTES_BEFORE_WARNING" | "THREE_HOURS_BEFORE_GIG_WARNING" | "THREE_HOURS_BEFORE_WARNING" | "TIMEOUT" | "TIMEOUT_APPLICANTS" | "TIMEOUT_CONFIRMATION" | "WORKER_APPROVE_TIMESHEET_EXPIRATION" | "WORKER_ARRIVING_WARNING" | "WORKER_AWAITING_START_WARNING" | "WORKER_INACTIVE_WARNING" | "WORKER_NOT_VERIFIED" | "WORKER_VERIFIED" | "%future added value";
export type ScheduledEventType = "BATCH_ENGAGEMENT_EVENT" | "DIRECT_HIRE_EVENT" | "GIG_LOTTERY_TIMED_EVENT" | "GIG_PREAUTHORIZATION_EVENT" | "GIG_SERIES_CONFIRMATION_CHECK" | "GIG_SERIES_CONFIRMATION_TIMEOUT" | "GIG_SERIES_EVENT" | "GIG_SERIES_TIMED_EVENT" | "GIG_STATE_EVENT" | "GIG_TIMED_ALERT" | "GIG_TIMED_EVENT" | "GIG_WORKER_ALERT" | "GIG_WORKER_EVENT" | "JOB_POSTING_EVENT" | "%future added value";
export type UserType = "ADMIN" | "ORGANIZATION_APPLICATION" | "ORGANIZATION_REQUESTER" | "REQUESTER" | "SYSTEM" | "WORKER" | "%future added value";
export type WorkerAttendanceStatus = "LATE_ARRIVAL" | "LATE_CANCELLATION" | "NO_SHOW" | "ON_TIME" | "%future added value";
export type WorkerTransportationState = "MOTORCYCLE" | "SEDAN" | "SUV" | "TRUCK" | "VAN" | "%future added value";
export type CqlFilterEngagementInput = {
  _and?: ReadonlyArray<CqlFilterEngagementInput> | null | undefined;
  _not?: CqlFilterEngagementInput | null | undefined;
  _or?: ReadonlyArray<CqlFilterEngagementInput> | null | undefined;
  actualStartsAt?: CqlOpDatetimeInput | null | undefined;
  anyEngagementDisputeDisposition?: CqlEnumEngagementDisputeDispositionInput | null | undefined;
  anyScheduledEventAction?: CqlEnumScheduledEventActionInput | null | undefined;
  anyScheduledEventType?: CqlEnumScheduledEventTypeInput | null | undefined;
  anyStateAction?: CqlEnumEngagementStateActionInput | null | undefined;
  anyStateName?: CqlEnumEngagementStateNameInput | null | undefined;
  anyStateOperationKey?: CqlOpStringInput | null | undefined;
  assignedAdminId?: CqlOpIdInput | null | undefined;
  attendanceStatus?: CqlEnumWorkerAttendanceStatusInput | null | undefined;
  audienceMembership?: CqlEnumGigAudienceTypeInput | null | undefined;
  averageWorkerRating?: CqlOpFloatInput | null | undefined;
  calculatedStart?: CqlOpDatetimeInput | null | undefined;
  cancellationReasonType?: CqlEnumCancellationReasonTypeInput | null | undefined;
  commitmentResponse?: CqlEnumEngagementCommitmentResponseInput | null | undefined;
  completedGigCount?: CqlOpIntegerInput | null | undefined;
  currentPredictionEnded?: CqlOpFloatInput | null | undefined;
  currentStateAction?: CqlEnumEngagementStateActionInput | null | undefined;
  currentStateName?: CqlEnumEngagementStateNameInput | null | undefined;
  currentStateOperationKey?: CqlOpStringInput | null | undefined;
  currentStateTransitionedAt?: CqlOpDatetimeInput | null | undefined;
  currentStateTransitionedBy?: CqlOpIdInput | null | undefined;
  currentStateTransitionedByType?: CqlEnumUserTypeInput | null | undefined;
  endsAt?: CqlOpDatetimeInput | null | undefined;
  estimatedAmount?: CqlOpFloatInput | null | undefined;
  excusable?: CqlOpBooleanInput | null | undefined;
  excusableUntil?: CqlOpDatetimeInput | null | undefined;
  excuseDisposition?: CqlEnumCancellationReasonExcuseDispositionInput | null | undefined;
  gigAllSlotsFilled?: CqlOpBooleanInput | null | undefined;
  gigEndsAt?: CqlOpDatetimeInput | null | undefined;
  gigId?: CqlOpIdInput | null | undefined;
  gigName?: CqlOpStringInput | null | undefined;
  gigSlots?: CqlOpIntegerInput | null | undefined;
  gigStateName?: CqlEnumGigStateNameInput | null | undefined;
  gigType?: CqlEnumGigTypeInput | null | undefined;
  hasDisputes?: CqlOpBooleanInput | null | undefined;
  hasPayout?: CqlOpBooleanInput | null | undefined;
  hasRequesterReview?: CqlOpBooleanInput | null | undefined;
  id?: CqlOpIdInput | null | undefined;
  insertedAt?: CqlOpDatetimeInput | null | undefined;
  isAsap?: CqlOpBooleanInput | null | undefined;
  lastKnownWorkerLocation?: CqlOpCoordinatesInput | null | undefined;
  locationStatus?: CqlEnumEngagementWorkerLocationStatusInput | null | undefined;
  organizationId?: CqlOpIdInput | null | undefined;
  organizationLocationId?: CqlOpIdInput | null | undefined;
  organizationName?: CqlOpStringInput | null | undefined;
  organizationPositionId?: CqlOpIdInput | null | undefined;
  organizationPositionName?: CqlOpStringInput | null | undefined;
  paidByRequester?: CqlOpBooleanInput | null | undefined;
  payRate?: CqlOpFloatInput | null | undefined;
  publishedAt?: CqlOpDatetimeInput | null | undefined;
  ratingOfRequester?: CqlOpIntegerInput | null | undefined;
  ratingOfWorker?: CqlOpIntegerInput | null | undefined;
  reportingType?: CqlOpDatetimeInput | null | undefined;
  requesterId?: CqlOpIdInput | null | undefined;
  requesterName?: CqlOpStringInput | null | undefined;
  startsAt?: CqlOpDatetimeInput | null | undefined;
  workerBelongsToGroup?: CqlOpBooleanInput | null | undefined;
  workerCanApproveTimesheet?: CqlOpBooleanInput | null | undefined;
  workerDistance?: CqlOpFloatInput | null | undefined;
  workerEmail?: CqlOpStringInput | null | undefined;
  workerEstimatedLate?: CqlOpBooleanInput | null | undefined;
  workerFullName?: CqlOpStringInput | null | undefined;
  workerGroupId?: CqlOpIdInput | null | undefined;
  workerId?: CqlOpIdInput | null | undefined;
  workerIsFavorite?: CqlOpBooleanInput | null | undefined;
  workerLocationReportedAt?: CqlOpDatetimeInput | null | undefined;
  workerMobileNumber?: CqlOpStringInput | null | undefined;
  workerName?: CqlOpStringInput | null | undefined;
  workerOver18?: CqlOpBooleanInput | null | undefined;
  workerTransportationState?: CqlEnumWorkerTransportationStateInput | null | undefined;
};
export type CqlOpDatetimeInput = {
  _eq?: string | null | undefined;
  _gt?: string | null | undefined;
  _gte?: string | null | undefined;
  _isNull?: boolean | null | undefined;
  _lt?: string | null | undefined;
  _lte?: string | null | undefined;
  _neq?: string | null | undefined;
};
export type CqlEnumEngagementDisputeDispositionInput = {
  _eq?: EngagementDisputeDisposition | null | undefined;
  _in?: ReadonlyArray<EngagementDisputeDisposition> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: EngagementDisputeDisposition | null | undefined;
};
export type CqlEnumScheduledEventActionInput = {
  _eq?: ScheduledEventAction | null | undefined;
  _in?: ReadonlyArray<ScheduledEventAction> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: ScheduledEventAction | null | undefined;
};
export type CqlEnumScheduledEventTypeInput = {
  _eq?: ScheduledEventType | null | undefined;
  _in?: ReadonlyArray<ScheduledEventType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: ScheduledEventType | null | undefined;
};
export type CqlEnumEngagementStateActionInput = {
  _eq?: EngagementStateAction | null | undefined;
  _in?: ReadonlyArray<EngagementStateAction> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: EngagementStateAction | null | undefined;
};
export type CqlEnumEngagementStateNameInput = {
  _eq?: EngagementStateName | null | undefined;
  _in?: ReadonlyArray<EngagementStateName> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: EngagementStateName | null | undefined;
};
export type CqlOpStringInput = {
  _eq?: string | null | undefined;
  _ilike?: string | null | undefined;
  _in?: ReadonlyArray<string> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: string | null | undefined;
};
export type CqlOpIdInput = {
  _eq?: string | null | undefined;
  _in?: ReadonlyArray<string> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: string | null | undefined;
};
export type CqlEnumWorkerAttendanceStatusInput = {
  _eq?: WorkerAttendanceStatus | null | undefined;
  _in?: ReadonlyArray<WorkerAttendanceStatus> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: WorkerAttendanceStatus | null | undefined;
};
export type CqlEnumGigAudienceTypeInput = {
  _eq?: GigAudienceType | null | undefined;
  _in?: ReadonlyArray<GigAudienceType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: GigAudienceType | null | undefined;
};
export type CqlOpFloatInput = {
  _eq?: number | null | undefined;
  _gt?: number | null | undefined;
  _gte?: number | null | undefined;
  _isNull?: boolean | null | undefined;
  _lt?: number | null | undefined;
  _lte?: number | null | undefined;
  _neq?: number | null | undefined;
};
export type CqlEnumCancellationReasonTypeInput = {
  _eq?: CancellationReasonType | null | undefined;
  _in?: ReadonlyArray<CancellationReasonType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: CancellationReasonType | null | undefined;
};
export type CqlEnumEngagementCommitmentResponseInput = {
  _eq?: EngagementCommitmentResponse | null | undefined;
  _in?: ReadonlyArray<EngagementCommitmentResponse> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: EngagementCommitmentResponse | null | undefined;
};
export type CqlOpIntegerInput = {
  _eq?: number | null | undefined;
  _gt?: number | null | undefined;
  _gte?: number | null | undefined;
  _in?: ReadonlyArray<number> | null | undefined;
  _isNull?: boolean | null | undefined;
  _lt?: number | null | undefined;
  _lte?: number | null | undefined;
  _neq?: number | null | undefined;
};
export type CqlEnumUserTypeInput = {
  _eq?: UserType | null | undefined;
  _in?: ReadonlyArray<UserType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: UserType | null | undefined;
};
export type CqlOpBooleanInput = {
  _eq?: boolean | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: boolean | null | undefined;
};
export type CqlEnumCancellationReasonExcuseDispositionInput = {
  _eq?: CancellationReasonExcuseDisposition | null | undefined;
  _in?: ReadonlyArray<CancellationReasonExcuseDisposition> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: CancellationReasonExcuseDisposition | null | undefined;
};
export type CqlEnumGigStateNameInput = {
  _eq?: GigStateName | null | undefined;
  _in?: ReadonlyArray<GigStateName> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: GigStateName | null | undefined;
};
export type CqlEnumGigTypeInput = {
  _eq?: GigType | null | undefined;
  _in?: ReadonlyArray<GigType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: GigType | null | undefined;
};
export type CqlOpCoordinatesInput = {
  _eq?: Coordinates | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: Coordinates | null | undefined;
  _stDwithin?: CqlOpDwithinInput | null | undefined;
  _stWithinBoundingBox?: BoundingBoxCoordinates | null | undefined;
};
export type Coordinates = {
  latitude: number;
  longitude: number;
};
export type CqlOpDwithinInput = {
  coordinates: Coordinates;
  distanceUnit?: DistanceUnit | null | undefined;
  radius: number;
};
export type BoundingBoxCoordinates = {
  northeastCoordinates: Coordinates;
  southwestCoordinates: Coordinates;
};
export type CqlEnumEngagementWorkerLocationStatusInput = {
  _eq?: EngagementWorkerLocationStatus | null | undefined;
  _in?: ReadonlyArray<EngagementWorkerLocationStatus> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: EngagementWorkerLocationStatus | null | undefined;
};
export type CqlEnumWorkerTransportationStateInput = {
  _eq?: WorkerTransportationState | null | undefined;
  _in?: ReadonlyArray<WorkerTransportationState> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: WorkerTransportationState | null | undefined;
};
export type requesterQuery$variables = {
  attendanceStartRange?: string | null | undefined;
  engagementsQuery?: CqlFilterEngagementInput | null | undefined;
  gigId: string;
  hasGigId: boolean;
  id: string;
  organizationId?: string | null | undefined;
  query: string;
};
export type requesterQuery$data = {
  readonly gig?: {
    readonly gigType?: GigType | null | undefined;
    readonly organization?: {
      readonly administrativeArea1: string | null | undefined;
      readonly allowsSmoking: boolean | null | undefined;
      readonly companySize: string | null | undefined;
      readonly countryCode: string | null | undefined;
      readonly description: string | null | undefined;
      readonly id: string;
      readonly industry: string;
      readonly locality: string | null | undefined;
      readonly logoUrl: string | null | undefined;
      readonly name: string;
      readonly officeType: string | null | undefined;
      readonly overallRating: number;
      readonly postalCode: string | null | undefined;
      readonly reviewsReceived: {
        readonly totalCount: number;
      } | null | undefined;
      readonly street1: string | null | undefined;
      readonly street2: string | null | undefined;
      readonly union: boolean | null | undefined;
      readonly website: string | null | undefined;
      readonly yearFounded: number | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"BlockOrganizationCollapsibleRow_shift">;
  } | null | undefined;
  readonly requester: {
    readonly biography?: string | null | undefined;
    readonly displayName?: string | null | undefined;
    readonly id?: string;
    readonly jobTitle?: string | null | undefined;
    readonly overallRating?: number;
    readonly primaryOrganization?: {
      readonly administrativeArea1: string | null | undefined;
      readonly allowsSmoking: boolean | null | undefined;
      readonly companySize: string | null | undefined;
      readonly countryCode: string | null | undefined;
      readonly description: string | null | undefined;
      readonly id: string;
      readonly industry: string;
      readonly locality: string | null | undefined;
      readonly logoUrl: string | null | undefined;
      readonly name: string;
      readonly officeType: string | null | undefined;
      readonly overallRating: number;
      readonly postalCode: string | null | undefined;
      readonly reviewsReceived: {
        readonly totalCount: number;
      } | null | undefined;
      readonly street1: string | null | undefined;
      readonly street2: string | null | undefined;
      readonly union: boolean | null | undefined;
      readonly website: string | null | undefined;
      readonly yearFounded: number | null | undefined;
    } | null | undefined;
    readonly profilePhoto?: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly reviewsReceived?: {
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
  readonly viewer: {
    readonly id?: string;
    readonly organizations?: {
      readonly totalCount: number;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"OrgAttendanceCard_worker">;
  } | null | undefined;
};
export type requesterQuery = {
  response: requesterQuery$data;
  variables: requesterQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attendanceStartRange"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "engagementsQuery"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gigId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasGigId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": [
    (v8/*: any*/),
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "query"
    }
  ],
  "concreteType": "OrganizationsConnection",
  "kind": "LinkedField",
  "name": "organizations",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "kind": "Variable",
  "name": "organizationId",
  "variableName": "organizationId"
},
v12 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "gigId"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v14 = [
  (v7/*: any*/)
],
v15 = {
  "kind": "InlineFragment",
  "selections": [
    (v13/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": (v14/*: any*/),
      "storageKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "overallRating",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": [
    (v8/*: any*/)
  ],
  "concreteType": "EngagementReviewsConnection",
  "kind": "LinkedField",
  "name": "reviewsReceived",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": "reviewsReceived(first:0)"
},
v18 = [
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "logoUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "industry",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "locality",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "postalCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "countryCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "administrativeArea1",
    "storageKey": null
  },
  (v16/*: any*/),
  (v17/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "street1",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "street2",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "companySize",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "website",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "union",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "yearFounded",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "officeType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowsSmoking",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  }
],
v19 = [
  (v13/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Organization",
    "kind": "LinkedField",
    "name": "organization",
    "plural": false,
    "selections": (v18/*: any*/),
    "storageKey": null
  }
],
v20 = {
  "kind": "InlineFragment",
  "selections": (v19/*: any*/),
  "type": "Gig",
  "abstractKey": null
},
v21 = {
  "kind": "InlineFragment",
  "selections": (v19/*: any*/),
  "type": "GigSeries",
  "abstractKey": null
},
v22 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "biography",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "primaryOrganization",
  "plural": false,
  "selections": (v18/*: any*/),
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "requesterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v7/*: any*/),
              (v10/*: any*/),
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "engagementsQuery",
                    "variableName": "engagementsQuery"
                  },
                  (v11/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "startDate",
                    "variableName": "attendanceStartRange"
                  }
                ],
                "kind": "FragmentSpread",
                "name": "OrgAttendanceCard_worker"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "condition": "hasGigId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "gig",
            "args": (v12/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "BlockOrganizationCollapsibleRow_shift",
                "selections": [
                  (v15/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              },
              (v20/*: any*/),
              (v21/*: any*/)
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": "requester",
        "args": (v22/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v7/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profilePhoto",
                "plural": false,
                "selections": [
                  (v23/*: any*/)
                ],
                "storageKey": null
              },
              (v24/*: any*/),
              (v16/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/)
            ],
            "type": "RequesterLike",
            "abstractKey": "__isRequesterLike"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "requesterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v28/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": [
                  (v8/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "where",
                    "variableName": "engagementsQuery"
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerGigStats",
                "kind": "LinkedField",
                "name": "gigStats",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "engagementsEnded",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  (v11/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "start",
                    "variableName": "attendanceStartRange"
                  }
                ],
                "concreteType": "WorkerAttendanceStats",
                "kind": "LinkedField",
                "name": "attendanceStats",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "onTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lateArrival",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lateCancellationTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lateCancellationExcused",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lateCancellationUnexcused",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "noShowTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "noShowExcused",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "noShowUnexcused",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "requester",
        "args": (v22/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v28/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profilePhoto",
                "plural": false,
                "selections": [
                  (v28/*: any*/),
                  (v23/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": (v14/*: any*/),
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              },
              (v24/*: any*/),
              (v16/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/)
            ],
            "type": "RequesterLike",
            "abstractKey": "__isRequesterLike"
          }
        ],
        "storageKey": null
      },
      {
        "condition": "hasGigId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "gig",
            "args": (v12/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v28/*: any*/),
              (v15/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "id": "qpid:GMlUo6AnVBW0qRJMoT3aUDvcAy7ETxYtmZZ6GXV5EN0",
    "metadata": {},
    "name": "requesterQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "23a98af23a67001e7d170f464f41783f";

export default node;
