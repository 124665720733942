import { ContentArea, IconText, Text, Well } from "@gigsmart/atorasu";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import AttendanceOverviewDetails from "../../attendance/AttendanceOverviewDetails";
import AttendanceOverviewRow from "../../attendance/AttendanceOverviewRow";
import type { SharedParamList } from "../../navigation/types";
import HomeScreenCard from "../../worker/HomescreenCard";
import type { OrgAttendanceCard_worker$key } from "./__generated__/OrgAttendanceCard_worker.graphql";

type Props = {
  organizationId: string;
};

export const OrgAttendanceCard = ({
  engagements,
  result,
  organizationId
}: FragmentContainerInnerComponentProps<
  OrgAttendanceCard_worker$key,
  Props
>) => {
  const navigation =
    useNavigation<
      AppNavigationProp<SharedParamList, "OrganizationAttendanceFilteredShifts">
    >();
  return (
    <HomeScreenCard
      headerTitle="Your Organization Attendance - Last 60 Days"
      headerIcon="chart-user"
      testID="org-attendance-card"
    >
      <Well color="primary" size="compact">
        <IconText
          icon="briefcase"
          size="small"
          color="primary"
          iconVariant="solid"
          spacing="compact"
        >
          <Text weight="bold">Work Summary</Text>
        </IconText>
      </Well>
      <ContentArea size="medium">
        <AttendanceOverviewRow
          title="Shifts Worked"
          count={engagements?.totalCount ?? 0}
          color="success"
          onPress={() =>
            navigation.push("OrganizationAttendanceFilteredShifts", {
              attendanceStatus: "ON_TIME",
              attendanceOverviewDays: "Last 60 days",
              orgId: organizationId,
              allShifts: true
            })
          }
        />
      </ContentArea>
      <Well color="primary" size="compact">
        <IconText
          icon="clock"
          size="small"
          color="primary"
          iconVariant="solid"
          spacing="compact"
        >
          <Text weight="bold">Attendance</Text>
        </IconText>
      </Well>
      <ContentArea size="medium">
        <AttendanceOverviewDetails
          fragmentRef={result}
          onPress={(status) =>
            navigation.push("OrganizationAttendanceFilteredShifts", {
              attendanceStatus: status,
              attendanceOverviewDays: "Last 60 days",
              orgId: organizationId,
              allShifts: false
            })
          }
        />
      </ContentArea>
    </HomeScreenCard>
  );
};

export default createRelayFragmentContainer<
  OrgAttendanceCard_worker$key,
  Props
>(
  graphql`
    fragment OrgAttendanceCard_worker on Worker
    @argumentDefinitions(
      startDate: { type: "DateTime" }
      endDate: { type: "DateTime" }
      organizationId: { type: "ID" }
      engagementsQuery: { type: "CqlFilterEngagementInput"}
    ) {
      id
      engagements(first: 0, where: $engagementsQuery) {
        totalCount
      }
      ...AttendanceOverviewDetails_worker
        @arguments(
          startDate: $startDate
          endDate: $endDate
          organizationId: $organizationId
        )
    }
  `,
  OrgAttendanceCard
);
