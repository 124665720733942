/**
 * @generated SignedSource<<453f3c2819fda8de4c55ba20ba7610f7>>
 * @relayHash 6237b3ac3a4f4f918810a06ad79a1456
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:8557P6uvVwlVRD4rmox-3PUhYFiEKyymwYDJWEEHZgg

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngagementCapabilityStatus = "AVAILABLE" | "RESTRICTED" | "UNAVAILABLE" | "%future added value";
export type EngagementCapabilityType = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_SYSTEM_TIMESHEET" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CALL" | "CANCEL" | "CANCEL_ENGAGEMENT" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "DISPUTE_TIMESHEET" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MARK_AS_COMPLETE" | "MODIFY_BID" | "MODIFY_OFFER" | "NEGOTIATE" | "OFFER" | "PAUSE" | "PAY_WITHOUT_TIMESHEET" | "PAY_WORKER_AGAIN" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_START" | "RESUME" | "REVIEW" | "SEND_MESSAGE" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "VIEW_BADGES" | "VIEW_MESSAGE" | "VIEW_QUESTIONNAIRE" | "%future added value";
export type reviewTimesheetScreenQuery$variables = {
  id: string;
};
export type reviewTimesheetScreenQuery$data = {
  readonly node: {
    readonly capabilities?: ReadonlyArray<{
      readonly expiresAt: string | null | undefined;
      readonly restrictedBy: {
        readonly message: string;
      } | null | undefined;
      readonly status: EngagementCapabilityStatus;
      readonly type: EngagementCapabilityType;
    }>;
    readonly gig?: {
      readonly estimatedMileage: number | null | undefined;
      readonly reconcilesAt: string | null | undefined;
    };
    readonly original?: {
      readonly id: string;
      readonly totalDurationWorked: string | null | undefined;
    } | null | undefined;
    readonly workerTimesheet?: {
      readonly editable: boolean;
      readonly id: string;
      readonly isOverridden: boolean;
      readonly totalDurationWorked: string | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"EngagementInfoRow_engagement" | "TimesheetTabs_engagement" | "WithEngagementCapability_engagement" | "timesheetEditHooksReset_engagement">;
  } | null | undefined;
};
export type reviewTimesheetScreenQuery = {
  response: reviewTimesheetScreenQuery$data;
  variables: reviewTimesheetScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementBillingInfo",
  "kind": "LinkedField",
  "name": "billingInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billableDuration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalDue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TotalServiceFee",
      "kind": "LinkedField",
      "name": "totalServiceFee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minimumAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percentageRate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentStyle",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalDurationWorked",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementTimesheet",
  "kind": "LinkedField",
  "name": "timesheet",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalBreakDuration",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reconcilesAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedMileage",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiresAt",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementCapability",
  "kind": "LinkedField",
  "name": "capabilities",
  "plural": true,
  "selections": [
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "restrictedBy",
      "plural": false,
      "selections": [
        (v12/*: any*/)
      ],
      "storageKey": null
    },
    (v13/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
},
v16 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "SYSTEM"
  }
],
v17 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "WORKER"
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "editable",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOverridden",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v23 = [
  (v2/*: any*/)
],
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "action",
  "storageKey": null
},
v26 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v27 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isApproved",
  "storageKey": null
},
v29 = {
  "kind": "Literal",
  "name": "first",
  "value": 50
},
v30 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": [
    {
      "transitionedAt": {
        "direction": "ASC"
      }
    }
  ]
},
v31 = [
  (v20/*: any*/),
  (v22/*: any*/),
  (v2/*: any*/)
],
v32 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EngagementStateOrOverridesEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v20/*: any*/),
          (v2/*: any*/),
          (v21/*: any*/),
          (v25/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "transitionedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "transitionedBy",
            "plural": false,
            "selections": (v31/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EngagementNegotiation",
            "kind": "LinkedField",
            "name": "negotiation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "payRate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "submittedBy",
                "plural": false,
                "selections": (v31/*: any*/),
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "overridesState",
                "plural": false,
                "selections": (v23/*: any*/),
                "storageKey": null
              }
            ],
            "type": "EngagementStateOverride",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementStateLocation",
                "kind": "LinkedField",
                "name": "workerTransitionLocation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latitude",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "EngagementState",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v33 = [
  (v18/*: any*/),
  (v28/*: any*/),
  (v19/*: any*/),
  (v6/*: any*/),
  (v10/*: any*/),
  (v7/*: any*/),
  (v2/*: any*/),
  (v24/*: any*/),
  {
    "alias": null,
    "args": [
      (v29/*: any*/),
      (v30/*: any*/),
      {
        "kind": "Literal",
        "name": "overridden",
        "value": true
      }
    ],
    "concreteType": "EngagementStateOrOverridesConnection",
    "kind": "LinkedField",
    "name": "states",
    "plural": false,
    "selections": (v32/*: any*/),
    "storageKey": "states(first:50,orderBy:[{\"transitionedAt\":{\"direction\":\"ASC\"}}],overridden:true)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "reviewTimesheetScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EngagementInfoRow_engagement"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TimesheetTabs_engagement"
              },
              {
                "kind": "InlineDataFragmentSpread",
                "name": "timesheetEditHooksReset_engagement",
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v8/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/),
              {
                "alias": "original",
                "args": (v16/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              },
              {
                "alias": "workerTimesheet",
                "args": (v17/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v7/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"WORKER\")"
              },
              {
                "kind": "InlineDataFragmentSpread",
                "name": "WithEngagementCapability_engagement",
                "selections": [
                  (v15/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "type": "Engagement",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reviewTimesheetScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v20/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "negotiable",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Requester",
                    "kind": "LinkedField",
                    "name": "requester",
                    "plural": false,
                    "selections": [
                      (v22/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "profilePhoto",
                        "plural": false,
                        "selections": [
                          (v20/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v23/*: any*/),
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v21/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "logoUrl",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v10/*: any*/),
                  (v24/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workerEta",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": [
                  (v25/*: any*/),
                  (v21/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "inactiveCancelStates",
                "args": [
                  (v26/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "WHERE action = INACTIVE_CANCEL"
                  }
                ],
                "concreteType": "EngagementStateOrOverridesConnection",
                "kind": "LinkedField",
                "name": "states",
                "plural": false,
                "selections": (v27/*: any*/),
                "storageKey": "states(first:0,query:\"WHERE action = INACTIVE_CANCEL\")"
              },
              {
                "alias": "original",
                "args": (v16/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v28/*: any*/),
                  (v10/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": "startedCount",
                    "args": [
                      (v26/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "query",
                        "value": "WHERE action = START"
                      }
                    ],
                    "concreteType": "EngagementStateOrOverridesConnection",
                    "kind": "LinkedField",
                    "name": "states",
                    "plural": false,
                    "selections": (v27/*: any*/),
                    "storageKey": "states(first:0,query:\"WHERE action = START\")"
                  },
                  (v2/*: any*/),
                  (v24/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      (v29/*: any*/),
                      (v30/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "overridden",
                        "value": false
                      }
                    ],
                    "concreteType": "EngagementStateOrOverridesConnection",
                    "kind": "LinkedField",
                    "name": "states",
                    "plural": false,
                    "selections": (v32/*: any*/),
                    "storageKey": "states(first:50,orderBy:[{\"transitionedAt\":{\"direction\":\"ASC\"}}],overridden:false)"
                  }
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              },
              {
                "alias": "workerTimesheet",
                "args": (v17/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": (v33/*: any*/),
                "storageKey": "timesheet(variant:\"WORKER\")"
              },
              {
                "alias": "requesterTimesheet",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "variant",
                    "value": "REQUESTER"
                  }
                ],
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": (v33/*: any*/),
                "storageKey": "timesheet(variant:\"REQUESTER\")"
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementCapability",
                "kind": "LinkedField",
                "name": "capabilities",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "restrictedBy",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Engagement",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:8557P6uvVwlVRD4rmox-3PUhYFiEKyymwYDJWEEHZgg",
    "metadata": {},
    "name": "reviewTimesheetScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b717079ad41b83aae5e12039e55d7541";

export default node;
