import { Picker } from "@gigsmart/atorasu";
import { type FormFieldOptions, useFormField } from "@gigsmart/fomu";
import React, { type ComponentProps, useEffect } from "react";

interface Props
  extends FormFieldOptions<string>,
    Omit<ComponentProps<typeof Picker>, "value" | "onChange" | "testID"> {
  testID?: string;
}

export default function FomuPickerV2({
  name,
  validates,
  testID,
  ...props
}: Props) {
  const { value, setValue, showErrors, errors } = useFormField(
    { name, validates },
    [props.options]
  );
  useEffect(() => {
    if (value !== null) showErrors();
  }, []);
  console.log("RDX", { name });
  return (
    <Picker
      {...props}
      testID={testID ?? `${name}-picker`}
      value={value}
      onChange={(value) => {
        setValue(value);
        showErrors();
      }}
      errors={errors}
    />
  );
}
