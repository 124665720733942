import {
  ContentArea,
  EmptyContentSurface,
  LoadingView,
  Screen,
  Stack,
  Surface,
  Text,
  Well
} from "@gigsmart/atorasu";
import { Form } from "@gigsmart/fomu";
import {
  type AppNavigationProp,
  type AppScreenProps,
  useNavigation
} from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import AttendanceOverviewDetails from "@gigsmart/seibutsu/attendance/AttendanceOverviewDetails";
import FomuPickerV2 from "@gigsmart/seibutsu/fomu/inputs/FomuPickerV2";
import type { SharedParamList } from "@gigsmart/seibutsu/navigation/types";
import { DateTime } from "luxon";
import React from "react";
import type { attendanceOverviewScreenQuery } from "./__generated__/attendanceOverviewScreenQuery.graphql";

type Props = AppScreenProps<SharedParamList, "ProfileAttendanceOverview">;
const currentDate = DateTime.now();

export default createSuspendedQueryContainer<
  attendanceOverviewScreenQuery,
  Props
>(
  function AttendanceOverviewScreen({ response, route }) {
    const { viewer } = response ?? {};
    const totalEngagementsScheduled =
      viewer?.gigStats?.engagementsScheduled ?? 0;
    const navigator =
      useNavigation<
        AppNavigationProp<SharedParamList, "ProfileAttendanceOverview">
      >();

    return (
      <Screen testID="attendance-overview-screen" color="surface">
        <Form
          onSubmit={() => {}}
          initialValues={{ daysFilter: "Last 60 days" }}
          onChange={({ values }) => {
            const currentParams = route?.params?.attendanceOverviewDays;
            if (values.daysFilter !== currentParams) {
              navigator.setParams({
                attendanceOverviewDays: values.daysFilter
              });
            }
          }}
        >
          <ContentArea>
            <Stack>
              <Text variant="header" weight="bold">
                Your Attendance Overview
              </Text>
              <Text>
                Your attendance for the past 60 days is here. You can also view
                the past 30 days, 90 days, and all time.
              </Text>
              {totalEngagementsScheduled === 0 ? (
                <EmptyContentSurface
                  iconName="user-clock"
                  message={
                    <Stack>
                      <Text variant="subheader" color="primary">
                        You have not worked any Shifts
                      </Text>
                      <Text>Your Attendance history will appear here</Text>
                    </Stack>
                  }
                />
              ) : (
                <>
                  <Well color="primary" size="compact">
                    <FomuPickerV2
                      name="daysFilter"
                      inputType="text"
                      eventTargetName="Start Date Picker"
                      options={[
                        {
                          label: "Last 30 days",
                          value: "Last 30 days"
                        },
                        {
                          label: "Last 60 days",
                          value: "Last 60 days"
                        },
                        {
                          label: "Last 90 days",
                          value: "Last 90 days"
                        },
                        {
                          label: "All Time",
                          value: "All Time"
                        }
                      ]}
                    />
                  </Well>
                  <ContentArea size="medium">
                    <AttendanceOverviewDetails
                      fragmentRef={viewer}
                      onPress={(attendanceStatus) =>
                        navigator.push("ProfileAttendanceFilteredShifts", {
                          attendanceStatus,
                          attendanceOverviewDays:
                            route?.params?.attendanceOverviewDays ??
                            "Last 60 days"
                        })
                      }
                    />
                  </ContentArea>
                </>
              )}
            </Stack>
          </ContentArea>
        </Form>
      </Screen>
    );
  },
  {
    query: graphql`
      query attendanceOverviewScreenQuery(
        $startDate: DateTime
        $endDate: DateTime
      ) {
        viewer {
          ... on Worker {
            id
            gigStats {
              engagementsScheduled
            }
            ...AttendanceOverviewDetails_worker @arguments(
              startDate: $startDate
              endDate: $endDate
            )
          }
        }
      }
    `,
    variables: ({ route }) => {
      const params = route.params.attendanceOverviewDays ?? "Last 60 days";
      if (params === "All Time") {
        return {};
      }
      const days = Number(params.split(" ")[1]);

      return {
        startDate: currentDate.minus({ days }).startOf("day").toString()
      };
    },
    FallbackComponent: () => (
      <Surface>
        <LoadingView size="xlarge" />
      </Surface>
    )
  }
);
