import {
  RadioButtonGroup,
  type RadioButtonGroupProps
} from "@gigsmart/atorasu";
import { type FormFieldOptions, Validator, useFormField } from "@gigsmart/fomu";
import { useHistory } from "@gigsmart/kaizoku";
import React from "react";

export interface Props<T>
  extends Omit<RadioButtonGroupProps<T>, "value" | "onChange"> {
  name: string;
  required?: boolean;
  onChange?: (value?: T) => void;
  validates?: FormFieldOptions<T>["validates"];
  updateRoute?: boolean;
  initialValue?: T;
}

export default function FomuRadioButtonGroup<T>({
  name,
  required,
  onChange,
  updateRoute,
  validates = [],
  initialValue,
  ...restProps
}: Props<T>) {
  const history = useHistory();
  const { value, setValue } = useFormField<T>({
    name,
    validates: [
      ...(required ? [Validator.presence()] : []),
      ...(Array.isArray(validates) ? validates : validates ? [validates] : [])
    ],
    initialValue
  });

  return (
    <RadioButtonGroup
      value={value}
      onChange={(value) => {
        setValue(value);
        onChange?.(value);
        if (updateRoute) history.replace({ queryParams: { [name]: value } });
      }}
      {...restProps}
    />
  );
}
