import {
  type Color,
  ContentArea,
  Divider,
  Icon,
  type IconName,
  IconText,
  Pressable,
  Row,
  Spacer,
  Stack,
  Text
} from "@gigsmart/atorasu";
import React from "react";

interface Props {
  title: string;
  icon?: IconName;
  color: Color;
  count: number;
  onPress?: () => void;
  children?: React.ReactNode;
}

export default function AttendanceOverviewRow({
  title,
  icon,
  color = "success",
  count = 0,
  onPress,
  children
}: Props) {
  const pressable = !!onPress;

  return (
    <Stack size="medium">
      <Pressable
        onPress={onPress}
        disabled={!pressable || count === 0}
        eventEntityType="AttendanceRowItem"
        eventTargetName="Attendance Row Item"
        testID={`attendance-row-item-${title}`}
      >
        <ContentArea size="none" variant="compact">
          <Row justifyContent="space-between">
            {icon ? (
              <IconText
                icon={icon}
                color={color}
                size="small"
                spacing="compact"
              >
                <Text color="black">{title}</Text>
              </IconText>
            ) : (
              <Text color="black">{title}</Text>
            )}
            <Row gap="compact" alignItems="flex-start">
              <Text color={color} weight="bold">
                {count}
              </Text>
              {pressable && count > 0 && (
                <Icon
                  name="chevron-right"
                  color="primary"
                  size="small"
                  variant="solid"
                />
              )}
              {pressable && count === 0 && <Spacer size="compact" horizontal />}
            </Row>
          </Row>
          {children}
        </ContentArea>
      </Pressable>
      <Divider />
    </Stack>
  );
}
