import {
  Button,
  Collapsible,
  ContentArea,
  HighlightedReminder,
  IconText,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { gigActive } from "@gigsmart/isomorphic-shared/gig/helpers";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { Fragment, useState } from "react";
import { getReportTextAndVerbiage } from "../worker/WorkerHiredModal";
import NextStepsShiftModal from "./NextStepsShiftModal";
import type { ShiftInfoCollapsable_engagement$key } from "./__generated__/ShiftInfoCollapsable_engagement.graphql";

interface Props {
  shiftsCount?: number;
}

const ALLOWED_STATES = [
  "APPLIED",
  "CONFIRMING",
  "OFFERED",
  "SCHEDULED",
  "EN_ROUTE",
  "AWAITING_START",
  "WORKING",
  "PAUSED",
  "PENDING_TIMESHEET_APPROVAL"
];

export function ShiftInfoCollapsable({
  shiftsCount,
  currentState,
  gig,
  worker
}: FragmentContainerInnerComponentProps<
  ShiftInfoCollapsable_engagement$key,
  Props
>) {
  const [showModalShiftNextSteps, setShowModalShiftNextSteps] = useState(false);

  const engagementState = currentState?.name;
  const isGigActive = gigActive(gig);
  const isMultiShiftGig = shiftsCount && shiftsCount > 1;

  const iconText = (
    <IconText
      icon="lightbulb-on"
      iconColor="primary"
      iconAlign="center"
      textWeight="bold"
      color="primary"
      spacing="compact"
      size="small"
    >
      Tips for Working this Shift
    </IconText>
  );

  const getTitle = () => {
    switch (engagementState) {
      case "APPLIED":
        return "You have successfully applied to this Shift! 🎉";
      case "CONFIRMING":
        return "You are currently being confirmed for this Shift, but you are NOT hired yet.";
      case "OFFERED":
        return shiftsCount && shiftsCount > 1
          ? `Congrats, you have received ${shiftsCount} offers! 🎉`
          : "Congrats, you have received an offer! 🎉";
      case "SCHEDULED":
        return "Congrats, you are hired to work this Shift! 🎉";
      default:
        return null;
    }
  };

  const getInfoText = () => {
    switch (engagementState) {
      case "APPLIED":
        return [
          "The Requester will review your application. If they have any questions, they will be able to message you through the app.",
          "If you are no longer available to work the Shift, you can withdraw your application."
        ];
      case "CONFIRMING": {
        return [
          getReportTextAndVerbiage(
            gig?.requiredReportTypes,
            worker?.verification?.reports
          )
        ];
      }
      case "OFFERED":
        return [
          `You can accept or reject ${
            isMultiShiftGig ? "each" : "the"
          } offer. {You are not hired to work ${
            isMultiShiftGig ? "any Shifts" : "this Shift"
          } until you have accepted ${
            isMultiShiftGig ? "each" : "the"
          } offer. Please do not show up to work a Shift unless you have been hired.}`,
          "If the Shift requires Verification, after accepting the offer the Verification process will begin. If you are no longer available to work, you can reject the offer."
        ];
      case "SCHEDULED":
        return isGigActive
          ? [
              "Be sure to use the app to go En Route, indicate you have arrived, and if your location is confirmed to be on-site through the app, you will be able to start your clock. For your safety, receiving payment for Shifts outside the app is prohibited."
            ]
          : [
              "Be sure to use the app to go En Route, indicate you have Arrived, and if your location is confirmed to be on-site through the app, you will be able to start your clock. For your safety, receiving payment for Shifts outside the app is prohibited."
            ];
      case "EN_ROUTE":
      case "AWAITING_START":
        return [
          "Be sure to use the app to go En Route, indicate you have arrived, and if your location is confirmed to be on-site through the app, you will be able to start your clock. For your safety, receiving payment for Shifts outside the app is prohibited."
        ];
      case "WORKING":
      case "PAUSED":
        return [
          "Your time will be tracked through the app. At the end of the Shift if there are any issues with your time worked you will be able to submit an updated timesheet for the Requester to review and they will be able to make any changes prior to approving your final timesheet."
        ];
      case "PENDING_TIMESHEET_APPROVAL":
        return [
          "Once your final timesheet is approved you will be able to rate and review the Requester and your earnings will be deposited in your Wallet. For your safety, receiving payment for Shifts outside the app is prohibited."
        ];
      default:
        return null;
    }
  };

  /*
    If a string contains partial elements that needs to be displayed as bold.
    This method tokenize the string based on opening tag '{' and closing tag '}'
    and renders everything in bold which is in between these tags
  */
  const renderPartialBoldText = (text: string) => {
    if (!text.includes("{")) {
      return text;
    }

    const splittedText = text.split("{");

    return splittedText.map((substring, index) => {
      if (!substring.includes("}")) {
        return (
          <Text key={`plain-${text}`} testID={`plain-${index}`}>
            {substring}
          </Text>
        );
      }

      const nestedString = substring.split("}");
      return (
        <Fragment key={index}>
          <Text weight="bold" key={`bold-${text}`} testID={`bold-${index}`}>
            {nestedString[0]}
          </Text>
          <Text key={`normal-${text}`} testID={`normal-${index}`}>
            {nestedString[1]}
          </Text>
        </Fragment>
      );
    });
  };

  if (!engagementState || !ALLOWED_STATES.includes(engagementState))
    return null;

  const title = getTitle();
  return (
    <>
      <Collapsible
        variant="surface"
        header={iconText}
        testID="shift-info-collapsable"
      >
        <ContentArea>
          <Stack>
            {title && (
              <Text weight="bold" testID="shiftInfoTitle">
                {title}
              </Text>
            )}
            {getInfoText()?.map((text, index) => (
              <Text key={text} testID={`shiftInfoText-${index}`}>
                {renderPartialBoldText(text)}
              </Text>
            ))}
            {engagementState === "CONFIRMING" && (
              <HighlightedReminder
                header="Do NOT show up for a Shift unless you are hired & notified through the app."
                icon="lightbulb-on"
              />
            )}
            <Button
              testID="shift-gig-next-steps"
              label="How to Work a Shift"
              outline
              size="small"
              onPress={() => setShowModalShiftNextSteps(true)}
            />
          </Stack>
        </ContentArea>
      </Collapsible>
      <NextStepsShiftModal
        showModal={showModalShiftNextSteps}
        handleClose={() => setShowModalShiftNextSteps(false)}
      />
    </>
  );
}

export default createRelayFragmentContainer<
  ShiftInfoCollapsable_engagement$key,
  Props
>(
  graphql`
    fragment ShiftInfoCollapsable_engagement on Engagement {
      currentState {
        name
      }
      gig {
        startsAt
        endsAt
        requiredReportTypes
      }
      worker {
        verification {
          reports {
            status
            type
          }
        }
      }
    }
  `,
  ShiftInfoCollapsable
);
