import { ContentArea, Screen, Surface, Text } from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import AttendanceFilteredEngagementList from "@gigsmart/seibutsu/attendance/AttendanceFilteredEngagementList";
import type { WorkerAttendanceStatus } from "@gigsmart/seibutsu/attendance/types";
import React from "react";
import type { WorkerParamList } from "../navigation/types";

type Props = AppScreenProps<WorkerParamList, "ProfileAttendanceFilteredShifts">;

const titleMap: Partial<Record<WorkerAttendanceStatus, string>> = {
  LATE_ARRIVAL: "Late Arrivals",
  LATE_CANCELLATION: "Late Cancellations",
  NO_SHOW: "No Shows",
  ON_TIME: "On-Time Arrivals"
};

export default function AttendanceFilteredShiftsScreen({ route }: Props) {
  const user = useCurrentUser();
  const id = user?.id;
  if (!id) return null;
  const attendanceStatus = route.params.attendanceStatus;
  const attendanceOverviewDays = route.params.attendanceOverviewDays;
  const getTitle = (status: WorkerAttendanceStatus): string => {
    return titleMap[status] ?? "Unknown Status";
  };

  return (
    <Screen
      testID="attendance-filtered-shifts-screen"
      header={
        <Surface>
          <ContentArea>
            <Text variant="header" weight="bold" color="primary">{`${getTitle(
              attendanceStatus
            )} - ${attendanceOverviewDays}`}</Text>
          </ContentArea>
        </Surface>
      }
    >
      <AttendanceFilteredEngagementList
        attendanceStatus={attendanceStatus}
        attendanceOverviewDays={attendanceOverviewDays}
        workerId={id}
      />
    </Screen>
  );
}
