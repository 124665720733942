/**
 * @generated SignedSource<<a8438151fab6e835450a860fe46ffd29>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AttendanceOverviewCard_worker$data = {
  readonly gigStats: {
    readonly engagementsScheduled: number;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"AttendanceOverviewDetails_worker">;
  readonly " $fragmentType": "AttendanceOverviewCard_worker";
};
export type AttendanceOverviewCard_worker$key = {
  readonly " $data"?: AttendanceOverviewCard_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"AttendanceOverviewCard_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AttendanceOverviewCard_worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerGigStats",
      "kind": "LinkedField",
      "name": "gigStats",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "engagementsScheduled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "endDate",
          "variableName": "endDate"
        },
        {
          "kind": "Variable",
          "name": "startDate",
          "variableName": "startDate"
        }
      ],
      "kind": "FragmentSpread",
      "name": "AttendanceOverviewDetails_worker"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "faa7767d3071d346288b87087075d5f0";

export default node;
