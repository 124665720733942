import { Bubble, ContentArea } from "@gigsmart/atorasu";
import React from "react";

interface Props {
  testID: string;
  label: string;
  onRemove?: () => void;
}

export default function FilterBubble({
  testID,
  label: rawLabel,
  onRemove
}: Props) {
  const label = rawLabel.length > 20 ? `${rawLabel.slice(0, 20)}...` : rawLabel;
  return (
    <ContentArea size="slim" variant="slim">
      <Bubble
        testID={testID}
        variant="solid"
        color="neutral"
        label={label}
        icon="times"
        iconPlacement="right"
        onIconPress={onRemove}
      />
    </ContentArea>
  );
}
