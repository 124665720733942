import {
  Button,
  Column,
  ContentArea,
  Divider,
  Icon,
  MaskedTextInput,
  RadioButtonRow,
  Screen,
  Stack,
  Surface,
  Text,
  useStepper
} from "@gigsmart/atorasu";
import {
  type FomuSubmitFn,
  Form,
  FormField,
  FormSubmit,
  Validator
} from "@gigsmart/fomu";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayMutation
} from "@gigsmart/relay";
import { DateTime } from "luxon";
import moment from "moment";
import React, { useState } from "react";
import { FomuAddressInput } from "../../../fomu/inputs/AddressInput";
import FomuTextInput from "../../../fomu/inputs/FomuTextInput";
import StepHeader from "../../../shared/Stepper/StepHeader";
import Nav from "./Nav";
import SelectOrganizationModal from "./SelectOrganizationModal";
import type { OrganizationStepDirectHireClaimQuery } from "./__generated__/OrganizationStepDirectHireClaimQuery.graphql";
import type {
  OrganizationStepUpdateDirectHireClaimMutation,
  UpdateDirectHireClaimInput
} from "./__generated__/OrganizationStepUpdateDirectHireClaimMutation.graphql";
import type { DirectHireClaimsStepper } from "./index";

const previousYear = new Date().getFullYear() - 1;

export default createSuspendedQueryContainer<OrganizationStepDirectHireClaimQuery>(
  function TypeStep({ response }) {
    const { stepsData, gotoStep } = useStepper<
      "organization",
      DirectHireClaimsStepper
    >();
    const [showModal, setShowModal] = useState(false);
    const [commit] =
      useRelayMutation<OrganizationStepUpdateDirectHireClaimMutation>(graphql`
        mutation OrganizationStepUpdateDirectHireClaimMutation(
          $input: UpdateDirectHireClaimInput!
        ) {
          updateDirectHireClaim(input: $input) {
            directHireClaim {
              id
              firstName
              lastName
              place {
                streetAddress
              }
              directHireDate
              directHireType
            }
          }
        }
      `);

    const claim = response?.node;
    const isHire = claim?.directHireType === "HIRE";

    const handleSubmit: FomuSubmitFn = ({ values }) => {
      const input: UpdateDirectHireClaimInput = {
        directHireDate: DateTime.fromFormat(
          values?.date,
          "MM/dd/yyyy"
        ).toISODate(),
        firstName: values?.firstName,
        lastName: values?.lastName,
        directHireClaimId: stepsData?.id ?? "",
        placeId: values.placeId
      };
      commit(
        { input },
        {
          onSuccess: () => {
            gotoStep(stepsData?.returnTo ?? "information", {
              ...stepsData,
              dirty: true,
              returnTo: null
            });
          }
        }
      );
    };
    return (
      <Screen testID="organization-step-screen">
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            address: claim?.place?.streetAddress,
            date:
              claim?.directHireDate &&
              DateTime.fromISO(claim?.directHireDate).toFormat("MM/dd/yyyy"),
            firstName: claim?.firstName,
            lastName: claim?.lastName
          }}
        >
          <Column fill justifyContent="space-between">
            <FormSubmit>
              {({ dirty }) => <Nav returnTo="information" dirty={dirty} />}
            </FormSubmit>
            <Stack>
              <StepHeader
                name={`Select the Requester who ${
                  isHire ? "Hired" : "Paid"
                } You`}
                note={`Please select the Requester that ${
                  isHire
                    ? "hired you to work outside of the app"
                    : "paid you outside the app and provide additional information about them"
                }.`}
              />
              <Surface>
                <ContentArea>
                  <Stack>
                    <Text weight="semibold">
                      Requester Organization{" "}
                      {!claim?.organization && (
                        <Text weight="semibold" color="danger">
                          (Required)
                        </Text>
                      )}
                    </Text>
                    {!!claim?.organization && (
                      <>
                        <Divider />
                        <Column fill>
                          <RadioButtonRow
                            testID="org-name"
                            eventTargetName="Org Name Radio Button"
                            title={claim?.organization?.name}
                            selected
                            onSelect={() => null}
                          />
                        </Column>
                        <Divider />
                      </>
                    )}
                    <Column alignItems="center">
                      <Button
                        testID="select-organization-btn"
                        variant={claim?.organization ? "clear" : "solid"}
                        icon={claim?.organization ? "edit" : undefined}
                        label={
                          claim?.organization
                            ? "Change Selection"
                            : "Select an Organization"
                        }
                        size="small"
                        onPress={() => setShowModal(true)}
                      />
                    </Column>
                  </Stack>
                </ContentArea>
              </Surface>
              <Surface>
                <ContentArea>
                  <Stack>
                    <Text weight="semibold">
                      What is the name of the person who{" "}
                      {isHire ? "hired" : "paid"} you?
                    </Text>
                    <FomuTextInput
                      name="firstName"
                      label="First Name"
                      validates={[Validator.presence()]}
                      placeholder="First Name"
                    />
                    <FomuTextInput
                      name="lastName"
                      label="Last Name"
                      validates={[Validator.presence()]}
                      placeholder="Last Name"
                    />
                  </Stack>
                </ContentArea>
              </Surface>
              <Surface>
                <ContentArea>
                  <Stack>
                    <Text weight="semibold">
                      What location{" "}
                      {isHire ? "were you hired at?" : "did you work?"}
                    </Text>
                    <FomuAddressInput label="Address" />
                  </Stack>
                </ContentArea>
              </Surface>
              <Surface>
                <ContentArea>
                  <Stack>
                    <Text weight="semibold">
                      What date were you {isHire ? "hired?" : "paid?"}
                    </Text>
                    <FormField
                      name="date"
                      validates={[
                        Validator.date({
                          format: "MM/DD/YYYY",
                          options: {
                            min: moment(`${previousYear}0101`, "YYYYMMDD"),
                            max: moment()
                          }
                        }),
                        Validator.length({ min: 10, message: "invalid date" })
                      ]}
                    >
                      {({ value, setValue, errors, showErrors }) => (
                        <MaskedTextInput
                          label={`${isHire ? "Hire" : "Payment"} Date`}
                          placeholder="MM/DD/YYYY"
                          value={value}
                          testID="date-of-birth-input"
                          leftAccessory={
                            <ContentArea size="none" variant="compact">
                              <Icon
                                name="calendar"
                                variant="solid"
                                size="small"
                                color="primary"
                              />
                            </ContentArea>
                          }
                          onChangeText={(val) => {
                            setValue(val);
                          }}
                          type="number"
                          mask="date"
                          note={`This date must be on or after January 1, ${previousYear}`}
                          errors={errors}
                          onBlur={showErrors}
                          textAlign="left"
                        />
                      )}
                    </FormField>
                  </Stack>
                </ContentArea>
              </Surface>
            </Stack>
            <FormSubmit>
              {({ invalid, submit }) => (
                <ContentArea>
                  <Button
                    testID="save-changes-btn"
                    disabled={invalid || !claim?.organization}
                    label="Save Changes"
                    onPress={submit}
                  />
                </ContentArea>
              )}
            </FormSubmit>
          </Column>
          <SelectOrganizationModal
            onCancel={() => setShowModal(false)}
            visible={showModal}
            directHireClaimId={stepsData?.id}
            initialOrgId={claim?.organization?.id}
          />
        </Form>
      </Screen>
    );
  },
  {
    query: graphql`
      query OrganizationStepDirectHireClaimQuery($id: ID!) {
        node(id: $id) {
          ... on DirectHireClaim {
            id
            firstName
            lastName
            place {
              streetAddress
            }
            directHireDate
            directHireType
            organization {
              id
              name
            }
          }
        }
      }
    `,
    variables: () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { stepsData } = useStepper<
        "organization",
        DirectHireClaimsStepper
      >();
      return { id: stepsData?.id ?? "" };
    },
    fetchPolicy: "store-only"
  }
);
