import {
  Button,
  DateTime,
  ModalBody,
  ModalFooter,
  Text
} from "@gigsmart/atorasu";
import { showModal } from "@gigsmart/atorasu";
import React from "react";

interface Props {
  organizationName: string;
  actualStartsAt: string;
  timezone: string;
  startsAt: string;
  endsAt: string;
  onClose: () => void;
}

function NoShowModal({
  onClose,
  organizationName,
  actualStartsAt,
  startsAt,
  endsAt,
  timezone
}: Props) {
  return (
    <>
      <ModalBody gap="standard">
        <Text>
          You were <Text weight="bold">marked as a No-Show</Text> for your Shift
          with {organizationName} on{" "}
          <DateTime
            actualStartsAt={actualStartsAt}
            startsAt={startsAt}
            endsAt={endsAt}
            timezone={timezone}
            separator=" from"
            size="lg"
          />
        </Text>
        <Text>
          This will be reflected in your profile and visible to hiring
          Organizations.
        </Text>
      </ModalBody>
      <ModalFooter>
        <Button testID="no-show-modal-cta" label="Got It" onPress={onClose} />
      </ModalFooter>
    </>
  );
}

export function showNoShowModal(props: Props) {
  showModal({
    eventContext: "no-show-modal",
    useModalBody: false,
    useModalFooter: false,
    title: "No Show",
    onRequestClose: props.onClose,
    children: (close) => (
      <NoShowModal
        {...props}
        onClose={() => {
          close();
          props.onClose();
        }}
      />
    )
  });
}
