import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";
import { DateTime } from "luxon";
import React from "react";
import WorkerProjectEngagementCard from "../engagement/WorkerProjectEngagementCard";
import { WorkerEngagementCardV2 } from "../gig-like/WorkerShiftCard";
import InfiniteGridListLayout from "../layouts/lists/InfiniteGridList";
import type { WorkerShiftParams } from "../shifts/navigation";
import type { AttendanceFilteredEngagementListPaginationQuery } from "./__generated__/AttendanceFilteredEngagementListPaginationQuery.graphql";
import type {
  AttendanceFilteredEngagementListQuery,
  CqlFilterEngagementInput
} from "./__generated__/AttendanceFilteredEngagementListQuery.graphql";
import type { AttendanceFilteredEngagementList_worker$key } from "./__generated__/AttendanceFilteredEngagementList_worker.graphql";
import type { WorkerAttendanceStatus } from "./types";

interface Props {
  attendanceStatus: WorkerAttendanceStatus;
  attendanceOverviewDays: string;
  organizationId?: string;
  workerId: string;
  allShifts?: boolean;
}

type NodeType = NonNullable<
  ObjectPath<
    AttendanceFilteredEngagementList_worker$key,
    [" $data", "engagements", "edges", 0, "node"]
  >
>;
const now = DateTime.now();

export default createSuspendedQueryContainer<
  AttendanceFilteredEngagementListQuery,
  Props
>(
  function WorkerEngagementHistoryList({
    attendanceStatus,
    attendanceOverviewDays,
    organizationId,
    allShifts,
    response
  }) {
    const worker = response?.node ?? null;
    const navigation = useNavigation<AppNavigationProp<WorkerShiftParams>>();
    const handlePress = (id: string, isProject: boolean) =>
      navigation.push(isProject ? "ProjectDetails" : "ShiftDetails", { id });

    return (
      <InfiniteGridListLayout<
        AttendanceFilteredEngagementListPaginationQuery,
        AttendanceFilteredEngagementList_worker$key,
        NodeType
      >
        parentRef={worker}
        searchPlaceholder="Search by Position name"
        testID="attendance-filtered-engagement-list"
        getRefetchVarsFromValuesFn={(values) => {
          return {
            where: getQuery({
              attendanceStatus,
              attendanceOverviewDays,
              organizationId,
              allShifts,
              searchTerm: values?.searchTerm
            }),
            orderBy: [{ actualStartsAt: { direction: "DESC" } }]
          };
        }}
        keyExtractor={(item, index) => item?.id || `${index}`}
        pageSize={12}
        fragmentInput={graphql`
          fragment AttendanceFilteredEngagementList_worker on Worker
          @refetchable(queryName: "AttendanceFilteredEngagementListPaginationQuery")
          @argumentDefinitions(
            count: { type: "Int", defaultValue: 24 }
            after: { type: "String" }
            where: { type: "CqlFilterEngagementInput" }
            orderBy: { type: "[CqlOrderEngagementInput!]" }
          ) {
            engagements(first: $count, after: $after, where: $where, orderBy: $orderBy)
              @connection(
                key: "AttendanceFilteredEngagementList_engagements"
                filters: ["where"]
              ) {
              edges {
                node {
                  id
                  currentState {
                    name
                  }
                  gigType
                  startsAt
                  ...WorkerEngagementCardV2_engagement
                  ...WorkerProjectEngagementCard_engagement
                }
              }
            }
          }
        `}
        getData={(data) => {
          return getConnectionNodes(data?.engagements);
        }}
        renderItem={(item) =>
          item?.gigType === "PROJECT" ? (
            <WorkerProjectEngagementCard
              fragmentRef={item}
              onPress={() => handlePress(item.id, true)}
            />
          ) : (
            <WorkerEngagementCardV2
              fragmentRef={item}
              onPress={() => handlePress(item.id, false)}
            />
          )
        }
      />
    );
  },
  {
    query: graphql`
      query AttendanceFilteredEngagementListQuery(
        $workerId: ID!
        $where: CqlFilterEngagementInput!
        $orderBy: [CqlOrderEngagementInput!]
      ) {
        viewer {
          __typename
        }
        node(id: $workerId) {
          ... on Worker {
            id
            ...AttendanceFilteredEngagementList_worker @arguments(where: $where, orderBy: $orderBy)
          }
        }
      }
    `,
    variables: ({
      attendanceStatus,
      attendanceOverviewDays,
      organizationId,
      workerId,
      allShifts
    }: Props) => {
      return {
        workerId,
        where: getQuery({
          attendanceStatus,
          attendanceOverviewDays,
          organizationId,
          allShifts
        }),
        orderBy: [{ actualStartsAt: { direction: "DESC" } }]
      };
    }
  }
);

export function getQuery({
  attendanceStatus,
  attendanceOverviewDays,
  searchTerm,
  organizationId,
  allShifts
}: {
  attendanceStatus: WorkerAttendanceStatus;
  attendanceOverviewDays: string;
  searchTerm?: string;
  organizationId?: string;
  allShifts?: boolean;
}): CqlFilterEngagementInput {
  const query: CqlFilterEngagementInput = allShifts
    ? {
        anyStateName: { _eq: "ENDED" }
      }
    : {
        attendanceStatus: { _eq: attendanceStatus }
      };

  if (attendanceOverviewDays !== "All Time") {
    query.actualStartsAt = {
      _gte: now
        .minus({ days: Number(attendanceOverviewDays.split(" ")[1]) })
        .startOf("day")
        .toString()
    };
  }

  if (organizationId) {
    query.organizationId = { _eq: organizationId };
  }

  if (searchTerm) {
    query.gigName = { _ilike: searchTerm };
  }

  return query;
}
